import React from 'react';


//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//INTERNAL COMPONENTS
import TopNavIcon from './topnavicons';
import ServiceMenu from './servicemenu';
import logo from './logo.png';

function TopNav(props){

    
    return(
        <React.Fragment>
            <Row>
                <Col><img src={logo}  className="img-container-5"/></Col>
            </Row>
        </React.Fragment>
    )

}
/*
  **className="bck-clr-primary-7 line-primary-6-bottom-1 *
  <Row className="p-t-10 p-b-25">
                
  {/**  className="p-t-10 p-b-10 line-primary-6-right-1"
  <Col lg={2}>
      <img src={logo} className="logo" />
  </Col>
  */
  /*

  <Col lg={3} className="p-t-50 p-l-50 font-150 clr-primary-3">{props.data.titleicon} &nbsp; {props.data.title}</Col>
  <Col lg={4}>&nbsp;</Col>
  <Col lg={3}>
      <TopNavIcon />
  </Col>
  </Row>
  
*/
export default TopNav;