export function createCollectionRequestStore(){
    return {
        publishcollections:[],
        addPublishCollection(publishdata){
            this.publishcollections.push(publishdata);
        },
        setPublishCollection(publishdatas){
            this.publishcollections=publishdatas;
        },
        usermsg:[],
        addUserMsg(usermsg){
            this.usermsg.push(usermsg);
        },
        setUserMsg(usermsgs){
            this.usermsg(usermsgs);
        },
        userdata:[],
        addUserData(userdata){
            console.info("obserable userdata");
            console.info(userdata);
            this.userdata.push(userdata);
        },
        requests:[],
        addRequest(request){
            this.requests.push(request);
        },
        setRequests(requests){
            this.requests=requests;

        },
        requestsel:{},
        setRequestSel(reqsel){
            this.requestsel=reqsel;
        },
        qboauth:{},
        setQboAuth(qboauthres){
            this.qboauth=qboauthres;
        },
        collections:[],
        addCollections(collections){
            this.collections.push(collections);
        },
        setCollections(collections){
            this.collections=collections
        }
    }
}