import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CustomDatePicker(props){

    const handleChange=(e)=>{
        console.info(e);
        props.cbfun(e);
    }

    return(
        <React.Fragment>
            <Row>
                <Col lg={3}>{props.label}</Col>
                <Col>
                <DatePicker
                selected={props?.datadate}
                onChange={(e)=>{handleChange(e)}} 
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="dd MMM yyyy hh:mm:ss a"
            />
                </Col>
            </Row>
          
            
            
        </React.Fragment>
    );

}
export default CustomDatePicker;