import React, {useState} from 'react';

import EventDataBarChart from "../datawidget/charts/EventDataBarChart";

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function EventDashboardContent() {
    return (
        <React.Fragment>
            <Row className="">
                <EventDataBarChart />

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold Today</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$2500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold This Week</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$5500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold This Month</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$8500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Total So Far</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$15500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
                <Col lg={2} md={10} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Total And Outstanding</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$75.50K / $35.00K</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
  
                
            </Row>
        </React.Fragment>
    );
}
export default EventDashboardContent;