import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';

import ImageUpload from '../../components/imageupload';


//INTERNAL
import RestServer from '../cRestServer';
import {orgurl}  from '../../environment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faQrcode, faPen} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';




function EditUserProfileContent() {

    //STORE SYSTEMS
    const { userData} = userDataStore();

    const[images, setImages]=useState(null);
    const[tags, setTags]=useState(null);
    const[user, setUser]=useState(null);
    const[company, setCompany]=useState(null);

    //contact info
    const[firstname, setFirstname]=useState(null);
    const[lastname, setLastname]=useState(null);
    const[description, setDescription]=useState(null);
    const[phone, setPhone]=useState(null);


    const onClickDelete =(i)=>{
        console.info("delete image");

        var eventdates=[];
        images.map((idata, index) =>{
            if(i !==index){
                eventdates.push(idata);
            }
        });
        setImages(eventdates);
    }

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

    const userType=(ut)=>{
        var rtn="";
        if(ut=="VS"){rtn="Venue Security User";}
        if(ut=="VH"){rtn="Venue Hostess User";}
        if(ut=="EV"){rtn="Event Vendor User";}
        if(ut=="OU"){rtn="Organization User";}

        return rtn;
    }

    useEffect(() => {
       new RestServer().setUrl(orgurl+'/api/org/user/'+userData[0].userid)
       .setMethod('GET')
       .flagReturnData(true)
       .connect()
       .then(response=>{
            const userDatartn=response.data;
            setUser(userDatartn);

            setFirstname(userDatartn.contact[0].firstname);
            setLastname(userDatartn.contact[0].lastname);
            setDescription(userDatartn.description)
            setPhone(userDatartn.contact[0].phone)
            setImages(userDatartn.userImage);

            new RestServer().setUrl(orgurl+'/api/org/company/'+userData[0].companyid)
           .setMethod('GET')
           .flagReturnData(true)
           .connect()
           .then(response=>{
                const companyData=response.data;
                console.info("companyData: ");
                console.info(companyData);
                setCompany(companyData);
            });
       });
    },[]);


    return(
        <React.Fragment>
             <Row>
                <Col lg={10} md={12}>
                    <Row>
                        <Col lg={4} md={12} className="p-r-25 p-b-25">
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col> Information</Col>
                            </Row>
                            <Row className="glass-container-1 p-b-25">
                                <Col>
                                    <Row className="p-t-10">
                                        <Col className="font-size-100 font-heavy8" >Company </Col>
                                        <Col>{company.companyname}</Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col className="font-size-100 font-heavy8" >Login Username</Col>
                                        <Col >{user.contact[0].emailaddress}</Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col className="font-size-100 font-heavy8" >User Type</Col>
                                        <Col>{userType(user.usertype)}</Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col className="font-size-100 font-heavy8" >Active</Col>
                                        <Col>
                                            <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            defaultChecked={user.active}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>

                        <Col lg={4} md={12}  className="p-r-25 p-b-25">
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col> Contact Information</Col>
                            </Row>
                            <Row className="glass-container-1">
                                <Col>
                                    <Row className="p-t-10">
                                        <Col >
                                            <Form.Group controlId ="firstname" onChange={(s)=>{setFirstname(s.target.value)}}>
                                                <Form.Label>Firstname</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={firstname} placeholder="First Name" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col >
                                            <Form.Group controlId ="lastname" onChange={(s)=>{setLastname(s.target.value)}}>
                                                <Form.Label>Lastname</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={lastname} placeholder="Last Name" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col>
                                            <Form.Group controlId ="eventdescription" onChange={(s)=>{setDescription(s.target.value)}}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={4} value={description} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row className="p-t-10 p-b-10">
                                        <Col >
                                            <Form.Group controlId ="Phone" onChange={(s)=>{setPhone(s.target.value)}}>
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={phone} placeholder="Phone Number" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={4} md={12}>
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Add Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            images?.map((image, index) =>{
                                                var eventimgloca=image.imageloc+image.imagename
                                                return <Row key={index} className="p-t-10 p-l-10 hoverpos">
                                                    <span className="remImage p-t-10" id="delete"></span>
                                                    <img src={eventimgloca}
                                                            className="p-t-10 img-container-2"
                                                            onClick={()=>{onClickDelete(index)}}
                                                            />
                                                </Row>
                                            })
                                        }
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                        <Row className="p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Tags</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row>
                                            <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                        </Row>
                                        <Row className="p-t-25 p-l-25 p-r-25">
                                            {
                                                tags?.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                                })
                                            }
                                        
                                        </Row>  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                    </Row>
                </Col>
                <Col lg={1} className="v-center">
                    <Row  className="p-t-50">
                        <Col lg={12} className="center">
                            <button className="icon-text-container-0 p-10 font-heavy9">
                                <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Update
                            </button>
                        </Col>
                    </Row>
                    <Row className="p-t-50">
                        <Col lg={12} className="center">
                            <button className="icon-text-container-0 p-10 font-heavy9">
                                <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                            </button>
                        </Col>
                    </Row>
                </Col>
             </Row>
            
        </React.Fragment>
    );
}
    
export default EditUserProfileContent;