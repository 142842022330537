import React, {useEffect, useState} from 'react';

import CustomDatePicker from '../../customdatepicker';
import CustomDropDown from '../../customdropdown';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';




import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';


import GenericVerticalBar from '../GenericVerticalBar';
import RestServer from "../../cRestServer"

import {orgurl, producturl}  from "../../../environment";

import {CommonUtil} from '../../CommonUtil';
import {dateFormat} from '../../DateFormatter';

import {StaticData} from "../../staticdata";

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen, faGear} from '@fortawesome/free-solid-svg-icons';


//STORE
import userDataStore from '../../../stores/zuserdatastore';


function EventDataBarChart(props) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Tooltip,
      );

      const[query, setQuery]=useState(null);
      const[dateConfig, setDateConfig]=useState(null);
      const[startDate, setStartDate]=useState(null);
      const[endDate, setEndDate]=useState(null);
      const[interval, setInterval]=useState(null);
      const[intervalTime, setIntervalTime]=useState(null);
      const [showModal, setShowModal]=useState(false);
      const[chartData, setChartData]=useState({
        labels: ['Loading'],
        datasets: [
          {
            data: [0],
            backgroundColor: 'rgba(255, 50, 50, 0.9)',
          },
          
        ],
      });


      const handleForm = (e)=>{
        e.preventDefault();
        const data={
            "eventId":"64516dc2077e195d511eed1d",
            "dataCalulation":[
                {
                    "id":0,
                    "outputName":"SUMTickets",
                    "calType":"SUM",
                    "outputType":"FLOAT",
                    "calData":[
                        {
                            "id":0,
                            "metadataType":"RT",
                            "metadataCode":"ETT",
                            "outputValueType":"FLOAT"
                        }
                    ]
                }
            ],
            "dateInfo":{
                "startTime":dateFormat.getEpochFromData(startDate),
                "endTime":dateFormat.getEpochFromData(endDate),
                "timeType":interval,
                "timeInteral":intervalTime
            }
        }

        console.info(data)
        setQuery(data);
        new RestServer().setUrl(producturl+'/api/data/queryeventdata')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const resp=response.data;

            //push labels
            var labelList=[];
            var dataList=[];

            resp.data.map((d)=>{
                labelList.push(dateFormat.getformateddate(d.dataX));
                dataList.push(d.dataY)

            })


            var data= {
                labels: labelList,
                datasets: [
                  {
                    data: dataList,
                    backgroundColor: 'rgba(255, 50, 50, 0.9)',
                  },
                  
                ],
            };
            setChartData(data);
            
        });

        

        setShowModal(false);

      }

    const handleCloseModel =(e)=>{
        e.preventDefault();
        setShowModal(false);
    }

    const handleTerminateModel=()=>{setShowModal(false);}

    const handleSetStartData=(e)=>{
        setStartDate(e);
    }
    const handleSetEndData=(e)=>{
        setEndDate(e);

    }
    const handleSetInterval=(e)=>{
        setInterval(e);
    }
    const handleSetIntervalTime=(e)=>{
        setIntervalTime(e);
    }

    const handleQuery=()=>{
        
    }


    useEffect(() => {

        const data={
            "eventId":"64516dc2077e195d511eed1d",
            "dataCalulation":[
                {
                    "id":0,
                    "outputName":"SUMTickets",
                    "calType":"SUM",
                    "outputType":"FLOAT",
                    "calData":[
                        {
                            "id":0,
                            "metadataType":"RT",
                            "metadataCode":"ETT",
                            "outputValueType":"FLOAT"
                        }
                    ]
                }
            ],
            "dateInfo":{
                "startTime":1686273001000,
                "endTime":1686532201000,
                "timeType":"MIN",
                "timeInteral":1
            }
        }
        setQuery(data)

      

        new RestServer().setUrl(producturl+'/api/data/queryeventdata')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const resp=response.data;

            //push labels
            var labelList=[];
            var dataList=[];

            resp.data.map((d)=>{
                labelList.push(dateFormat.getformateddate(d.dataX));
                dataList.push(d.dataY)

            })


            var data= {
                labels: labelList,
                datasets: [
                  {
                    data: dataList,
                    backgroundColor: 'rgba(255, 50, 50, 0.9)',
                  },
                  
                ],
            };
            setChartData(data);
            
        });
        
        
    },[]);

    
    return (
        <React.Fragment>
            <Col lg={11} md={11} className="glass-container-1">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Ticket Sold Per Every {query?.dateInfo?.timeInteral} {query?.dateInfo?.timeType}</Col>
                        <Col onClick={(s)=>{setShowModal(true)}} className="p-l-10 p-t-10 clr-primary-7 font-size-100 font-heavy rad-2 bck-clr-primary-4 m-t-10 m-l-10 m-r-10 right">
                       <FontAwesomeIcon icon={faGear} />
                        </Col>
                       
                    </Row>
                   
                    <Row className="p-t-25 font-size-250 font-heavy9 p-l-10 p-r-10 center">
                        <Col>
                            <Bar width={100} height={10} data={chartData} />
                        </Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This is the number of tickets sold per day for this event
                        </Col>
                    </Row>
                </Col>
                
                {/*Add Event Ticket*/}
                <Modal show={showModal} onHide={handleTerminateModel}>
                    <Form onSubmit={handleForm}>
                        <Modal.Header closeButton>
                        <Modal.Title>Configure Data Widget</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="emailaddress">
                                        
                                        <CustomDatePicker datadate={startDate}  label="Start Date" cbfun={handleSetStartData}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                    <Col lg={12}>  
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            
                                            <CustomDatePicker datadate={endDate}  label="End Date" cbfun={handleSetEndData}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="username">
                                        
                                        <CustomDropDown data={StaticData.GetIntervalData()}  title="Interval :" initdd="Interval" cbfun={handleSetInterval} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="emailaddress" >
                                        
                                        <CustomDropDown data={StaticData.GetIntervalTime()}  title="Interval Time :" initdd="Frequency" cbfun={handleSetIntervalTime} />
                                    </Form.Group>
                                </Col>
                            </Row> 
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModel}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
        </React.Fragment>

    );
}
export default EventDataBarChart;