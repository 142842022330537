import React,{useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faTicket, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';
import RestServer from '../../components/cRestServer';
import {producturl}  from '../../environment';

import CurrentRevenue from '../../components/datawidget/currentrevenue';
import ProjectedRevenue from '../../components/datawidget/projectedrevenue';
import TicketSold from '../../components/datawidget/ticketsold';
import TicketRedeemed from '../../components/datawidget/ticketredeemed'

import EventManagementNav from '../../components/events/eventmanagementnav';
import ListEvents from '../../components/events/listevents';
import ListEvents2 from '../../components/events/listevents2';

//STORES
import userDataStore from '../../stores/zuserdatastore';




function EventList() {


       //STORE SYSTEMS
       const { userData} = userDataStore();

       const [events, setEvents]=useState([]);

   

    useEffect(() => {
        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info(response.data)
            setEvents(response.data);
        });
    },[]);

    return (
        <React.Fragment>
            <Container fluid>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                        <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Event Management</Col>
                        <Col lg={3} md={6}><TopRightNav /></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2}> <LeftNav /></Col>
                        <Col lg={11} md={10}><ListEvents2 /></Col>
                    </Row>
                </Col>
            </Container>
        </React.Fragment>
    );
}
export default EventList;