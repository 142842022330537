import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL
import ImageUpload from '../../components/imageupload';
import DateSelector from '../../components/dateselector';
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faCalendarPlus, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';

//STORE
import userDataStore from '../../stores/zuserdatastore';

function EditProductContent() {

    //STORES
    const { userData} = userDataStore();

    const history = useNavigate();


    //FORM Information
    const[product, setProduct]=useState(null);
    const[productName, setProductName] = useState(null);
    const[productDescription, setProductDescription] = useState(null);
    const[price, setPrice]=useState(null);
    const[venueid, setVenueId]=useState(null);
    const[selectedVenue, setSelectedVenue]=useState(null);
    const[images, setImages]=useState(null);

    //Modal Control
    const [venues, setVenues]=useState([]);

    const[tags, setTags]=useState([]);

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

    const onClickDelete =(i)=>{
        console.info("delete image");

        var eventdates=[];
        images.map((idata, index) =>{
            if(i !==index){
                eventdates.push(idata);
            }
        });

        setImages(eventdates);
    }

    const handleEditProduct = (e)=>{
        e.preventDefault();


        const data ={
            productId:product.productId,
            productName:productName,
            productDescription:productDescription,
            venueid:venueid,
            companyid:userData[0].companyid,
            price:price,
            isTaxable:false,
            eventticketrequired:true,
            images:JSON.parse(localStorage.getItem('imagedatastate'))
        }
        console.info(data);

    
        new RestServer().setUrl(producturl+'/api/prod/updateproduct')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                localStorage.setItem("imagedatastate", null);
                history('/products', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
        
             
    }
    
    useEffect(() => {
        const urlData = window.location.pathname.split("/");

        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const vdata = response.data
            setVenues(vdata);

            new RestServer().setUrl(producturl+'/api/prod/productbyid/'+urlData[2])
            .setMethod('GET')
            .flagReturnData(true)
            .connect()
            .then(response=>{
                const prod = response.data
    
                setProduct(prod);
                setProductName(prod.productName);
                setProductDescription(prod.productDescription);
                setPrice(prod.price);
                setVenueId(prod.venueid);
                setImages(prod.images);

                vdata.map((vd)=>{
                    if(vd.venueid === prod.venueid){
                        setSelectedVenue(vd);
                        setVenueId(vd.venueid);
                    }
                })
            });

        });

       

    },[]);
    

    return (
    <React.Fragment>
        <Row>
            <Col lg={11}>
                <Row>

                    <Col  lg={4} md={10} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Product Information</Col>
                        </Row>
                        <Row className="glass-container-1">
                            <Col>
                                <Row className="p-t-25">
                                    <Col >
                                        <Form.Group controlId ="productname" onChange={(s)=>{setProductName(s.target.value)}}>
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Control className="clr-black" type="text" value={productName} placeholder="product name" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25">
                                    <Col>
                                        <Form.Group controlId ="productdescription" onChange={(s)=>{setProductDescription(s.target.value)}}>
                                            <Form.Label>Product Description</Form.Label>
                                            <Form.Control as="textarea" value={productDescription}  rows={4} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25">
                                    <Col >
                                        <Form.Group controlId ="price" onChange={(s)=>{setPrice(s.target.value)}}>
                                            <Form.Label>Product Price</Form.Label>
                                            <Form.Control className="clr-black" value={price} type="text" placeholder="product price" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25 p-b-50">
                                <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Venue</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setVenueId(s.target.value)}}>
                                            <option value={selectedVenue?.venueid}>{selectedVenue?.venuename}</option>
                                            {
                                                venues?.map((venue)=>{
                                                    return <option value={venue.venueid}>{venue.venuename}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col  lg={4} md={10} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Tags</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                            <Col>
                                <Row>
                                    <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                </Row>
                                <Row className="p-t-25 p-l-25 p-r-25">
                                    {
                                        tags.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                        })
                                    }
                                        
                                </Row>  
                            </Col>
                        </Row>
                    </Col>

                    <Col  lg={4} md={10} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            images?.map((image, index) =>{
                                                var eventimgloca=image.imageloc+image.imagename
                                                return <Row key={index} className="p-t-10 p-l-10 hoverpos">
                                                    <span className="remImage p-t-10" id="delete"></span>
                                                    <img src={eventimgloca}
                                                            className="p-t-10 img-container-2"
                                                            onClick={()=>{onClickDelete(index)}}
                                                            />
                                                </Row>
                                            })
                                        }
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
            <Col lg={1} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9" onClick={handleEditProduct}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Update
                        </button>
                    </Col>
                </Row>
                <Row className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
       
    </React.Fragment>
    );
}

export default EditProductContent;