import React from 'react';
import {createCollectionRequestStore} from './stores/collectionrequeststore';
import {useLocalStore} from 'mobx-react';

const RequestContext = React.createContext(null);

export const RequestProvider = ({children})=>{

    const requestStore =  useLocalStore(createCollectionRequestStore)

    return <RequestContext.Provider value={requestStore}>
        {children}
    </RequestContext.Provider>
}
export const useRequestStore = ()=>React.useContext(RequestContext)