import React,{useState, useEffect} from 'react';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';
import RestServer from '../../components/cRestServer';
import {orgurl}  from '../../environment';

import EventManagementNav from '../../components/events/eventmanagementnav';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

import Form from 'react-bootstrap/Form';


//INTERNAL COMPONENTS
import ListVenues from '../../components/venues/listvenues';
import Logo from '../../components/ui/logo';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faTicket} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';

function VenueList() {

    const [venues, setVenues]=useState([]);

    //STORE SYSTEMS
    const { userData} = userDataStore();

    const basedata={
        title:'Event Management',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Venues'
    }

    useEffect(() => {
        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setVenues(response.data);
        });
    },[]);    

    return (
        <React.Fragment>
        <Container fluid>
            <Col>
                <Row className="p-t-25">
                    <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                    <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Venue Management</Col>
                    <Col lg={3} md={6}><TopRightNav /></Col>
                </Row>
            </Col>
            <Col>
                <Row className="p-t-25">
                    <Col lg={1} md={2}> <LeftNav /></Col>
                    <Col lg={11} md={10}><ListVenues /></Col>
                </Row>
            </Col>
        </Container>
    </React.Fragment>
      
    );
}

export default VenueList;