import React, {useState} from 'react';
import '../../../node_modules/react-vis/dist/style.css';
import {RadialChart} from 'react-vis';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import CurrentRevenue from '../datawidget/currentrevenue';
import ProjectedRevenue from '../datawidget/projectedrevenue';
import TicketSold from '../datawidget/ticketsold';
import TicketRedeemed from '../datawidget/ticketredeemed'




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faInfoCircle, faTrash, faChartPie, faChevronRight, faExpandArrowsAlt} from '@fortawesome/free-solid-svg-icons';


function ListEvents(props) {
    const myData = [{angle: 1500}, {angle: 2500}]

    console.info(props)
    const event = props.data;
    const image = event.eventimage[0].imageloc+event.eventimage[0].imagename;
    return (
        <React.Fragment>
            <Container fluid>
                <Col  lg={2} className="bck-clr-primary-7 rad-5 shadow ">
                    <Row >
                        <Col>
                            <img src={image}  className="display-img-1 rad-tl-5 rad-tr-5"/>
                        </Col>
                    </Row>
                    <Row className="p-t-25 font-size-75 font-heavy p-l-10 p-r-10 clr-primary-4">
                        <Col>January 20 2022</Col>
                    </Row>
                    <Row className="p-t-10 font-size-150 font-heavy p-l-10 p-r-10">
                        <Col>{event.eventname}</Col>
                    </Row>
                    <Row className="p-t-10 font-size-75 p-l-10  p-r-10">
                        <Col>{event.eventdescription}</Col>
                    </Row>
                    <Row className="p-t-50 p-b-25 p-l-10  p-r-10 font-heavy font-size-125 clr-primary-8 center">
                        <Col className="btn-sq-lg"><FontAwesomeIcon icon={faEdit} /></Col>
                        <Col><FontAwesomeIcon icon={faInfoCircle} /></Col>
                        <Col><FontAwesomeIcon icon={faChartPie} /></Col>
                        <Col><FontAwesomeIcon icon={faTrash} /></Col>
                    </Row>

                </Col>
            </Container>
        </React.Fragment>
    );
}
export default ListEvents;