import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import create from 'zustand';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL COMPONENTS
import RestServer from '../components/cRestServer';
import {orgurl}  from '../environment';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';

//STORES
import {useRequestStore} from '../collectionrequestcontext';
import userDataStore from '../stores/zuserdatastore';



function Login(){

    const [username, setUsername]=useState('');
    const [emailaddress, setEmailaddress]=useState('');
    const [password, setPassword]=useState('');
    const [showModel, setShowModel] = useState(false);

    const store = useRequestStore();
    const {addUserData, clearUserData} = userDataStore();

    const history = useNavigate();

    const handleCloseModel=()=>{
       // setShowModel(false);
    }

    const handleForgetPassword=(e)=>{
       // setShowModel(true);
    }
    const handleLogin = (e)=>{
        /*
        e.preventDefault();

        const data={
            username:username,
            password:password
        }
        new RestServer().setUrl(orgurl+'/api/org/login')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.active === true){
                const now = new Date()  
                const userinfo={
                    companyid:rtndata.companyid,
                    userid:rtndata.userid,
                    access_token:rtndata.token.access_token,
                    refresh_expires_in:rtndata.token.refresh_expires_in,
                    refresh_token:rtndata.token.refresh_token,
                    expires_in:rtndata.token.expires_in,
                    current_time:Math.round(now.getTime() / 1000)

                };
                clearUserData();
                addUserData(userinfo);
                history('/events', {replace:true})
            }else{
                toast("Login failed..please try again")
            }

        });
        */

    }

    const handleFormSubmit=(e)=>{
       // e.preventDefault();
    }
    useEffect(() => {
        console.info("inside useEffect" )
        //window.location.href="https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz_be&client_id=d1ba152e-c37a-47ef-81f2-ee8991620afa&nonce=defaultNonce&redirect_uri=https%3A%2F%2F192.168.68.56%3A3000%2Floginredirect&scope=openid&response_type=id_token&prompt=login";
        window.location.href="https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz_be&client_id=d1ba152e-c37a-47ef-81f2-ee8991620afa&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fportal.evntxz.com%2Floginredirect&scope=openid&response_type=id_token&prompt=login";
        //window.location.href="https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz_be&client_id=d1ba152e-c37a-47ef-81f2-ee8991620afa&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Floginredirect&scope=openid&response_type=id_token&prompt=login";
        //window.location.replace("https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz&client_id=da12b6a1-fcb9-4403-a3a7-53513e53713c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fd30ojr2uwf5tod.evntxz.com%2Flogin&scope=openid&response_type=id_token&prompt=login");
    });


    return (
        <React.Fragment>
             
        {/*<Row className="height-100 bck-clr-primary-5 p-t-100 p-b-100">
            <Col lg={3} className="rad-10 shadow cnt-align">
                <Row>
                    <Col className="font-200 p-t-10 p-b-10 center clr-primary-1">
                        Evntxz
                    </Col>
                </Row>
                <Row>
    <Col className="bck-clr-primary-3">*/}
                        {/*<Logo />*/}
                        
                    {/*</Col>
                </Row>
                <Row className="p-t-50 p-l-25 left clr-primary-2">
                    <Col className="font-heavy font-size-125 ">Sign In</Col>
                    <Col className="right p-r-25"><a href="/signup">Create Account</a></Col>
                </Row>
                <Form>
                    <Row>
                        <Col className="p-t-50">
                        <Form.Group controlId="username" onChange={(s)=>{setUsername(s.target.value)}}>
                            <Form.Label className="left">Username</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-t-25">
                        <Form.Group controlId="password" onChange={(s)=>{setPassword(s.target.value)}}>
                            <Form.Label className="left">Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter email" />
                        </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>
                        </Col>
                        <Col><a href="#" onClick={handleForgetPassword}>Forgot Password</a></Col>
                    </Row>
                    {/*
                    <Row className="p-t-50">
                        <Col lg={12}>
                            <a href="#" onClick={handleLogin}>
                                <span className="bck-clr-primary-1 p-r-100 p-l-100 p-t-10 p-b-10 rad-5 font-heavy ">Sign In</span>
                            </a>
                        </Col>
                    </Row>
                    */}
{/*
                    <Row className="p-t-50">
                        <Col lg={6}>
                            <a href="#" className="button-1" onClick={handleLogin}>
                                <FontAwesomeIcon icon={faSignInAlt} />&nbsp;&nbsp; Sign In
                            </a>
                        </Col>
                        
                    </Row>

                </Form>

            </Col>
        </Row>


         {/*Reset Password Model*/}
         {/*
         <Modal show={showModel} onHide={handleCloseModel}>
        <Form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row className="p-b-25">
                <Col>
                    <Form.Group controlId ="username" onChange={(s)=>{setUsername(s.target.value)}}>
                        <Form.Control className="clr-black" type="text" placeholder="username" />
                    </Form.Group>
                </Col>
            </Row>  
            <Row className="p-b-25">
                <Col>
                    <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailaddress(s.target.value)}}>
                        <Form.Control className="clr-black" type="text" placeholder="email address" />
                    </Form.Group>
                </Col>
            </Row>  
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModel}>
            Cancel
        </Button>
        <Button variant="primary" type="submit">
            Reset Password
        </Button>
        </Modal.Footer>
        </Form>
    </Modal> 
                */}
    </React.Fragment>
    );
}
export default Login;