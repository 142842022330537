import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


//INTERNAL COMPONENTS
import RestServer from '../../components/cRestServer';
import {producturl, orgurl}  from '../../environment';

//INTERNAL
import ImageUpload from '../../components/imageupload';


//STORES
import userDataStore from '../../stores/zuserdatastore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faQrcode, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';


function AddVendorContent() {

    const { userData} = userDataStore();
    const history = useNavigate();

    const[vendorname, setVendorName] = useState('');
    const[vendordescription, setVendordescription] = useState('');

    const[vendorContactNumber, setVendorContactNumber]=useState("");
    const[vendorEmailAddress, setVendorEmailAddress]=useState("");
    const [events, setEvents]=useState([]);
    const [eventId, setEventId]=useState('');

        //Venue Address
        const[vendorAddress, setVendorAddress] = useState("");
        const[vendorCity, setVendorCity]=useState("");
        const[vendorProvince, setVendorProvince]=useState("");
        const[vendorPostalCode, setVendorPostalCode]=useState("");
        const[vendorCountry, setVendorCountry]=useState("");

        const[tags, setTags]=useState([]);


    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

    const handleCreateVendor = (e)=>{
        console.info("handleCreateVendor")
        console.info("userData: ");
        console.info(userData);

        const contact={
            emailaddress:vendorEmailAddress,
            phone:vendorContactNumber

        };

        var contactsInfo=[];
        contactsInfo.push(contact);

       const address={
        address1:vendorAddress,
        city:vendorCity,
        state:vendorProvince,
        zip:vendorPostalCode,
        country:vendorCountry
       };

        const data={
            vendorname:vendorname,
            vendordescription:vendordescription,
            companyId: userData[0].companyid,
            eventId:eventId,
           // metadata:tags,
            contacts:contactsInfo,
            vendorAddress:address,
            vendorimage:JSON.parse(localStorage.getItem('imagedatastate')),
        }

        console.info("data: ");
        console.info(data);
       
        new RestServer().setUrl(orgurl+'/api/org/vendor')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                history('/vendors', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
        
    }

    useEffect(() => {
        console.info("userDataStore.useEffect :");
       /*
        const djson ={
            day:"02"
        }
        console.info(dateFormat.getd(djson));
        */


        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("ListEvents2->useEffect:")
            console.info(response.data)
            
            setEvents(response.data);
        });
    },[]);


    return (
        <React.Fragment>
            <Row>
            <Col>
                <Row>
                    <Col lg={4} md={10} className="p-r-25">
                        <Row className="p-b-25  p-t-25  font-size-125 font-heavy9">
                            <Col> Vendor Information sda</Col>
                        </Row>
                        <Row className="glass-container-1">
                            <Col>
                            <Row className="p-t-25">
                                <Col >
                                    <Form.Group controlId ="eventname" onChange={(s)=>{setVendorName(s.target.value)}}>
                                        <Form.Label>Vendor Name</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder="vendor name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col>
                                    <Form.Group controlId ="eventdescription" onChange={(s)=>{setVendordescription(s.target.value)}}>
                                        <Form.Label>Vendor Description</Form.Label>
                                        <Form.Control as="textarea" rows={4} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10 p-b-10">
                                <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Events</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setEventId(s.target.value)}}>
                                            <option>Select Event...</option>
                                            {
                                                events.map((event)=>{
                                                    return <option value={event.eventId}>{event.eventname}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecontactnumber" onChange={(s)=>{setVendorContactNumber(s.target.value)}}>
                                                <Form.Label>Vendor Contact Number</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Vendor Contact Number" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25 ">
                                        <Col >
                                            <Form.Group controlId ="venueemailaddress" onChange={(s)=>{setVendorEmailAddress(s.target.value)}}>
                                                <Form.Label>Vendor Email Address 2</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Vendor Email" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                         
                            <Row className="p-t-25">

                            </Row>
                    
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} md={10} className="p-r-25">
                        <Row className="p-b-25 p-t-25 font-size-125 font-heavy9">
                            <Col>Add Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                        <Row className="p-t-25">
                            <Col>
                                <Row className="p-b-25 font-size-125 font-heavy9">
                                    <Col>Tags</Col>
                                </Row>
                                <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row>
                                            <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                        </Row>
                                        <Row className="p-t-25 p-l-25 p-r-25">
                                            {
                                                tags.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                                })
                                            }
                                        
                                        </Row>  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} md={10} className="p-r-25">
                         {/* CONTACTS & Tags */}
                <Row >
                    <Col >
                    <Row className="p-b-25 p-t-25 font-size-125 font-heavy9">
                                <Col>Venue Address</Col>
                            </Row>
                             <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                                <Col>
                                    <Row className="p-t-10">
                                        <Col >
                                            <Form.Group controlId ="venueaddress" onChange={(s)=>{setVendorAddress(s.target.value)}}>
                                                <Form.Label>Vendor Address</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Venue Address" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecity" onChange={(s)=>{setVendorCity(s.target.value)}}>
                                                <Form.Label>City</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="City" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venueprovince" onChange={(s)=>{setVendorProvince(s.target.value)}}>
                                                <Form.Label>Province / State</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Province" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="vnuepostalcode" onChange={(s)=>{setVendorPostalCode(s.target.value)}}>
                                                <Form.Label>Postal Code / Zip</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Postal Code" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecountry" onChange={(s)=>{setVendorCountry(s.target.value)}}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control className="clr-black" type="text" placeholder="Country" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                    </Col>
                </Row>
                    </Col>

              

                   
                </Row>

               

            </Col>

            <Col lg={1} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9" onClick={handleCreateVendor}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Create
                        </button>
                    </Col>
                </Row>
                <Row className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </React.Fragment>
    );
}

export default AddVendorContent;