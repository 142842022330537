import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

//INTERNAL COMPONENTS
import RestServer from '../../components/cRestServer';
import {producturl}  from '../../environment';

import Common from '../ultility/common';
import {dateFormat} from '../DateFormatter';
import {CommonUtil} from '../CommonUtil';

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

//FONT ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarCheck, faEdit, faInfoCircle, faChartPie, faTrash, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';

function ListEvents2() {
    //STORE SYSTEMS
    const { userData} = userDataStore();
    const [events, setEvents]=useState([]);
    const history = useNavigate();

   const routeClick=(path)=>{
        history(path, {replace:true})
   }
   
   const validateImage=(e)=>{
    var rtnImageUrl="";

    if(e.eventimage !==null){
        rtnImageUrl=e.eventimage[0].imageloc+e.eventimage[0].imagename;
    }
    return rtnImageUrl;

   }

   const eventStatus=(e)=>{
    //UPCOMING, STARTED, FINISHED

    var rtn=null;
    //find the earliest start
    var earliestEvent=0;
    //find the latest start
    var latestEvent=0;

    e?.map((t)=>{
        if(earliestEvent===0){
            earliestEvent=t.epochtime;
        }else{
            if(earliestEvent<=t.epochtime){
                earliestEvent=t.epochtime;
            }
        }

        if(latestEvent===0){
            latestEvent=t.epochtime;
        }else{
            if(earliestEvent>=t.epochtime){
                latestEvent=t.epochtime;
            }
        }
    })
    const dateRange ={
        startDate: earliestEvent,
        endDate:latestEvent
    };

    //is start date in the future
    if(dateFormat.futureEvent(dateRange)){
        rtn="UPCOMING";
    }else if(dateFormat.pastEvent(dateRange)){
        rtn="FINISHED";
    }else if(dateFormat.eventStarted(dateRange)){
        rtn="STARTED";
    }else{
        rtn="UNKNOWN";
    }

    return rtn;

   }

   const delEvent=(eid)=>{
    console.info("delEvent: ")

    const data={
        eventId:eid
    }
    console.info(data);
    new RestServer().setUrl(producturl+'/api/prod/delevent')
    .setMethod('DELETE')
    .flagReturnData(true)
    .setPostBody(data)
    .connect()
    .then(response=>{
        const rtndata =response.data;
        if(rtndata.messageTypeID===1){
            new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
            .setMethod('GET')
            .flagReturnData(true)
            .connect()
            .then(response=>{
                setEvents(response.data);
            });
        }else{
            toast(rtndata.Message);
        }
    });
   }

    useEffect(() => {
        console.info("userDataStore.useEffect :");
       /*
        const djson ={
            day:"02"
        }
        console.info(dateFormat.getd(djson));
        */


        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("ListEvents2->useEffect:")
            console.info(response.data)
            
            setEvents(response.data);
        });
    },[]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={10} md={8}>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                    <button className="icon-text-container-1 m-r-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck} className="p-r-20"/>Started</button>
                    <button className="icon-text-container-1 m-l-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck} className="p-r-20"/>Upcoming</button>
                    
                 </Col >
                 <Col lg={2} md={4} className="right p-r-50">
                 <button className="icon-text-container-0 m-l-100 font-heavy font-size-75 p-r-20" onClick={()=>{routeClick("/addevent")}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Add
                </button>
                 </Col>
                </Row>
                <Row className="p-l-10 p-t-25">
                {
                    events?.map((event)=>{
                        const image = validateImage(event)

                        return <Col lg={2} md={6} className="glass-container-1 m-10">
                      
                                    <Row className="p-t-10">
                                        <Col>
                                            <img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-50 font-size-75 font-heavy p-l-10 p-r-10 clr-primary-4">
                                        <Col>Status : {eventStatus(event?.eventdates)}</Col>
                                    </Row>
                                    <Row className="p-t-5 font-size-150 font-heavy p-l-10 p-r-10">
                                        <Col>{event?.eventname}</Col>
                                    </Row>
                                    <Row className="p-t-10 p-b-50 font-size-75 p-l-10  p-r-10 container-h-150">
                                        <Col>{CommonUtil.Crop(event?.eventdescription, 350)}</Col>
                                    </Row>

                                    <Row className="p-t-25 p-b-10">
                                        <Col>
                                            <button className="icon-container-1" onClick={()=>{routeClick("/editevent/"+event?.eventId)}}>
                                                <FontAwesomeIcon icon={faCalendarCheck} />
                                                <span className="icon-container-1-title">Edit
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                        <Col>
                                            <button className="icon-container-1" onClick={()=>{routeClick("/eventdashboard/"+event.eventId)}}>
                                                <FontAwesomeIcon icon={faChartPie} />
                                                <span className="icon-container-1-title">Dashboard
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                        <Col>
                                            <button className="icon-container-1" onClick={()=>{delEvent(event.eventId)}}>
                                                <FontAwesomeIcon icon={faTrash} />
                                                <span className="icon-container-1-title">Delete
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                    </Row>
                            
                                </Col>
                                
                                
                    })
                }
                   


                </Row>
            
        </React.Fragment>
    );
}
export default ListEvents2;