import React, {useState} from 'react';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';


import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';



import EditVendorContent from '../../components/vendor/editvendorcontent';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faTicket} from '@fortawesome/free-solid-svg-icons';



function EditVendor() {
   

    return (
        <React.Fragment>
            <Container fluid>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                        <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Add Vendor</Col>
                        <Col lg={3} md={6}><TopRightNav /></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2}> <LeftNav /></Col>
                        <Col lg={11} md={10}><EditVendorContent /></Col>
                    </Row>
                </Col>
            </Container>
        </React.Fragment>
    );
}

export default EditVendor;