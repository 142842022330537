import React, {useState} from 'react';
import { toast } from 'react-toastify';

/*INTERNAL COMPONENT */
import RestServer from '../components/cRestServer';
import {orgurl}  from '../environment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';

function Signup(){

    const [email, setEmail]=useState('');
    const [username, setUsername]=useState('');
    const [password, setPassword]=useState('');


    const handleSignup = (e)=>{
        e.preventDefault();
        const data={
            email:email,
            signupType:"EndUser",
            username:username,
            password:password
        }
        
        new RestServer().setUrl(orgurl+'/api/org/usersign')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            toast(response.data.message);
        });
        

    }

    return (
        <React.Fragment>
            <Row className="height-100 bck-clr-primary-5 p-t-100 p-b-100">
                <Col lg={3} className="rad-10 shadow cnt-align">
                    <Row>
                        <Col className="font-200 p-t-10 p-b-10 center clr-primary-1">
                            Evntxz
                        </Col>
                    </Row>
                    <Row>
                        <Col className="bck-clr-primary-3">
                            {/*<Logo />*/}
                        </Col>
                    </Row>
                    <Row className="p-t-50 p-l-25 left clr-primary-2">
                        <Col className="font-heavy font-size-125 ">&nbsp;</Col>
                        <Col className="right p-r-25"> <a href="/login">Sign in</a></Col>
                    </Row>
                    <Form>
                        <Row>
                            <Col className="p-t-50">
                            <Form.Group controlId="email" onChange={(s)=>{setEmail(s.target.value)}}>
                                <Form.Label className="left">Email Address</Form.Label>
                                <Form.Control type="Email Address" placeholder="Enter email" />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="p-t-25">
                            <Form.Group controlId="username" onChange={(s)=>{setUsername(s.target.value)}}>
                                <Form.Label className="left">Username</Form.Label>
                                <Form.Control type="Username" placeholder="Enter email" />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="p-t-25">
                            <Form.Group controlId="password" onChange={(s)=>{setPassword(s.target.value)}}>
                                <Form.Label className="left">Password</Form.Label>
                                <Form.Control type="Password"  />
                            </Form.Group>
                            </Col>
                        </Row>
                        {/*
                        <Row className="p-t-50">
                            <Col lg={12}><span  onClick={handleSignup}><span className="bck-clr-primary-1 p-r-100 p-l-100 p-t-10 p-b-10 rad-5 font-heavy ">Create Account</span></span></Col>
                        </Row>
                        */}

                        <Row className="p-t-50">
                            <Col lg={12}>
                                <a href="#" className="button-1" onClick={handleSignup}>
                                    <FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp; Create Account
                                </a>
                            </Col>
                        </Row>

                    </Form>

                </Col>
            </Row>
        </React.Fragment>
    );

}
export default Signup;