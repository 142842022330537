import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

import { css } from "@emotion/css";

//CSS
import './toprightnav.css';


//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//FONT ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLock, faBell, faCommentAlt} from '@fortawesome/free-solid-svg-icons';

import profile1 from './profile1.jpg';

function TopRightNav(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useNavigate();

    const easeSlow = css`transition: all 450ms ease-in-out;`;

    const icon_container_1=css`
        z-index: 3;
        width: 45px;
        height: 45px;
        border-radius: 0.545454rem;
     
        margin: 5px;
        border: 1px solid var(--clr_pri_1);
        background-color: var(--clr_pri_0);
        color: var(--clr-primary-2);
        &:hover{
            z-index: 4;
            background-color:var(--clr_pri_1);
            box-shadow: 
            12px 12px 16px 0 rgba(0, 0, 0, 0.25),
            -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
            transition: 0.5s;
        }
    `;

    
    const menuOverlay = css`
  z-index: 1;
  position: fixed;
  top: 75px;
  right: 100px;
  width: 3.5vw;
  transform: translateY(-150%);
  transition: visibility 0ms 2ms, opacity 2ms linear;
  transition: all 500ms ease-in-out;
  &.show {
    transform: translateY(0%); 
  }
  nav {

      transition: all 1ms ease-in-out;
      transition: visibility 0ms 2ms, opacity 2ms linear;
    
  }
 
`;
const routeClick=(path)=>{
    history(path, {replace:true})
}

    const toggleMenu=(e)=>{
        console.info("toggleMenu");
        console.info(isMenuOpen);
        setIsMenuOpen(!isMenuOpen);
      }

    return(
        <React.Fragment>
            <Row className="right p-r-50">
          
                <Col>
                    {/*
                    <span className=" p-r-10"> <button className="icon-container-1"><FontAwesomeIcon icon={faCommentAlt} /></button></span>
                    <span className=" p-r-10"> <button className="icon-container-1"><FontAwesomeIcon icon={faBell} /></button></span>
                    */}
                    <span className=" p-r-25"> <button className="icon-container-1" onClick={()=>{routeClick("/login")}}><FontAwesomeIcon icon={faLock} /></button></span>
                    <span><img src={profile1}  className="img-container-circle-5"/></span>            
                    
                </Col>
                 {/*
            <Col>
           
                <button 
                    className={`${icon_container_1} ${isMenuOpen ? "closer" : null}`}
                    onClick={toggleMenu}><FontAwesomeIcon icon={faGear} />
                </button>
                <div className={`${menuOverlay} ${isMenuOpen ? "show" : null}`}>
                <nav className="font-100 right p-t-25">
                    <button className={`${icon_container_1}`}><FontAwesomeIcon icon={faFileInvoice} /></button>
                    <button className={`${icon_container_1}`}><FontAwesomeIcon icon={faUsers} /></button>
                    <button className={`${icon_container_1}`}><FontAwesomeIcon icon={faSign} /></button>
                </nav>
                </div>
            </Col>
    */}
            </Row>
        </React.Fragment>
    )


}
export default TopRightNav;