import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen, faPlus, faCancel, faUserAlt, faMobileAndroidAlt, faPlusCircle, faSquareCheck, faRectangleXmark} from '@fortawesome/free-solid-svg-icons';

//INTERNAL
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';

//STORE
import userDataStore from '../../stores/zuserdatastore';

function DeviceInfo() {
    const history = useNavigate();

    const[showModal, setShowModal]=useState(false);
    const[showEditModal, setShowEditModal]=useState(false);
    const[showSwithUserModal, setShowSwithUserModal]=useState(false);
    const[deviceName, setDeviceName]=useState(null)
    const[editDeviceName, setEditDeviceName]=useState(null)
    const[devices, setDevices]=useState([]);
    const[deviceDescription, setDeviceDescription]=useState(null);
    const[editDeviceDescription, setEditDeviceDescription]=useState(null);
    const[venues, setVenues]=useState([]);
    const[editSelectedVenue, setEditSelectedVenue]=useState(null);
    const[venueid, setVenueId]=useState('');
    const[editVenueid, setEditVenueId]=useState('');
    const[events, setEvents]=useState([]);
    const[editSelectedEvent, setEditSelectEvent]=useState(null);
    const[eventid, setEventId]=useState(null);
    const[editEventid, setEditEventId]=useState(null);
    const[users, setUsers]=useState(null);
    const[editSelectedUser, setEditSelectedUser]=useState(null)
    const[userId, setUserId]=useState(null);
    const[editUserId, setEditUserId]=useState(null);
    const[deviceimeicode, setDeviceImeiCode]=useState(null);
    const[editDeviceimeicode, setEditDeviceImeiCode]=useState(null);
    const[editDeviceId, setEditDeviceId]=useState(null);


     //STORES
     const { userData} = userDataStore();

     const handleTerminateModel=()=>{setShowModal(false)}
     const handleTerminateEditModel=()=>{setShowModal(false)}
     const handleTerminateSwitchUserModel=()=>{showSwithUserModal(false)}

     

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const handleCloseModel =(e)=>{
        e.preventDefault();
        setShowModal(false);
    }

    const handleCloseSwitchUserModel =(e)=>{
        e.preventDefault();
        setShowSwithUserModal(false);
    }

    const handleCloseEditModel =(e)=>{
        e.preventDefault();
        setShowEditModal(false);
    }

    const findEventName=(e)=>{
        var evenname;
        events.map((r)=>{
            if(r.eventid === e){
                evenname=r.eventname;
            }
        });
        return evenname;
    }

    
    const handleSwitchUserDeviceModel=(e)=>{
        console.info(e);
        //set device info for edit
       
        devices.map((d)=>{
            if(d.deviceid===e.deviceid){
                setEditDeviceName(d.devicename);
                setEditDeviceDescription(d.devicedescription);
                setEditDeviceImeiCode(d.deviceIMEICode);
                setEditDeviceId(e.deviceid);
            }
        });
        users.map((u)=>{
            if(u.userid ==e.loginUserId){
                setEditSelectedUser(u);
                setEditUserId(e.loginUserId);
            }
        });
        
        setShowSwithUserModal(true);
    }

    const handleEditDeviceModel=(e)=>{
        console.info(e);
        //set device info for edit
       
        devices.map((d)=>{
            if(d.deviceid===e.deviceid){
                setEditDeviceName(d.devicename);
                setEditDeviceDescription(d.devicedescription);
                setEditDeviceImeiCode(d.deviceIMEICode);
                setEditDeviceId(e.deviceid);
            }
        });
       
        venues.map((v)=>{
            if(v.venueid ==e.venueid){
                setEditSelectedVenue(v);
                setEditVenueId(e.venueid);
            }
        });

        events.map((e)=>{
            if(e.eventid ==e.eventid){
                setEditSelectEvent(e);
                setEditEventId(e.eventid)
            }
        });
        users.map((u)=>{
            if(u.userid ==e.loginUserId){
                setEditSelectedUser(u);
                setEditUserId(e.loginUserId);
            }
        });
        
        setShowEditModal(true);
        




    }

    const handleSwitchUserForm=(e)=>{
        e.preventDefault();
        const data={
            deviceid:editDeviceId,
            loginUserId:editUserId
        }

        new RestServer().setUrl(orgurl+'/api/org/deviceswitchuser')
        .setMethod('PUT')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
           
            new RestServer().setUrl(orgurl+'/api/org/companydevice/'+userData[0].companyid)
            .setMethod('GET')
            .flagReturnData(true)
            .connect()
            .then(resp=>{
                const rtn=resp.data;

                setShowModal(false);
                toast(rtndata.Message);
        
                setDevices(rtn); 
        });
        });
    }

    const handleEditForm=(e)=>{
        e.preventDefault();

        const data={
            deviceid:editDeviceId,
            devicename:editDeviceName, 
            devicedescription: editDeviceDescription,
            venueid:editVenueid,
            companyid:userData[0].companyid,
            eventid:editEventid,
            deviceIMEICode: editDeviceimeicode,
            loginUserId:editUserId
        }
        console.info("handleAddForm :")
        console.info(data)
       
        new RestServer().setUrl(orgurl+'/api/org/updatedevice')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            console.info(rtndata)
            setShowEditModal(false);
            toast(rtndata.Message);
        });

    }

  

    const enableDisable=(id, status)=>{
        const data={
            deviceid:id,
            isActive:status
        }

        new RestServer().setUrl(orgurl+'/api/org/enabledisabledevice')
        .setMethod('PUT')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
           
            new RestServer().setUrl(orgurl+'/api/org/companydevice/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(resp=>{
            const rtn=resp.data;

            setShowModal(false);
            toast(rtndata.Message);
        
            setDevices(rtn); 
        });
        });
    }

    const handleAddForm = (e)=>{
        e.preventDefault();

        const data={
            devicename:deviceName, 
            devicedescription: deviceDescription,
            venueid:venueid,
            companyid:userData[0].companyid,
            eventid:eventid,
            deviceIMEICode: deviceimeicode,
            loginUserId:userId
        }
        console.info("handleAddForm :")
        console.info(data)
       
        new RestServer().setUrl(orgurl+'/api/org/device')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            setShowModal(false);
            toast(rtndata.Message);
        });
         /*

        new RestServer().setUrl(orgurl+'/api/org/companydevice/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setDevices(response.data);
        });
        */

    }

    useEffect(() => {
        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("companyvenues: ");
            console.info(response.data);
            setVenues(response.data);
        });

        new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("companyusers: ");
            console.info(response.data);
            setUsers(response.data);
        });

        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("allcompanyevents: ");
            console.info(response.data);
            setEvents(response.data);
        });

        var toparray=[];
        new RestServer().setUrl(orgurl+'/api/org/companydevice/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtn=response.data;
            console.info("companydevice->rtn:");
            console.info(rtn);
        
            setDevices(rtn); 
        });

    },[]);
    

    return (
        <React.Fragment>
            <Row>
                <Col lg={10} md={7}>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                    <button className="icon-text-container-1 m-r-10 font-heavy font-size-75"><FontAwesomeIcon icon={faMobileAndroidAlt} className="p-r-20"/>Active</button>
                    <button className="icon-text-container-1 m-l-10 font-heavy font-size-75"><FontAwesomeIcon icon={faMobileAndroidAlt} className="p-r-20"/>De-Active</button>
                    
                 </Col>
                 <Col lg={2} md={5} className="right p-r-50">
                 <button className="icon-text-container-0 m-l-100 font-heavy font-size-75" onClick={(s)=>{setShowModal(true)}}>
                    <FontAwesomeIcon icon={faPlusCircle}  className="p-r-20"/>Add
                </button>
                 </Col>
            </Row>
            <Row className="p-l-10 p-t-25">
            {
                devices?.map((d)=>{
                    return <Col lg={3} md={11} className="glass-container-1 m-10">
                         <Row><Col className="font-heavy9"> {findEventName(d.eventid)}</Col></Row>
                        <Row className="p-t-25"><Col><FontAwesomeIcon icon={faMobileAndroidAlt} /> {d?.devicename}</Col></Row>
                        <Row><Col className="p-b-10"><span className="font-heavy">Device Status:</span> {d.active ? "Active" : "InActive" }</Col></Row>
                        <Row><Col className="p-t-10 font-size-75">{d?.devicedescription}</Col></Row>
                        
                       
                        <Row className="p-t-50 p-b-10 p-l-10  p-r-10">
                            <Col>
                                <button className="icon-container-1" onClick={(s)=>{handleSwitchUserDeviceModel(d)}}>
                                    <FontAwesomeIcon icon={faUserAlt} />
                                    <span className="icon-container-1-title">Switch User
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>
                            </Col>
                            <Col>
                                <button className="icon-container-1" onClick={(s)=>{handleEditDeviceModel(d)}}>
                                    <FontAwesomeIcon icon={faPen} />
                                    <span className="icon-container-1-title">Edit
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>
                            </Col>
                            <Col>
                            {d.active? <button className="icon-container-1" onClick={(i,s)=>{enableDisable(d.deviceid, false)}}>
                                    <FontAwesomeIcon icon={faRectangleXmark} />
                                    <span className="icon-container-1-title">De-Activate
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>: <button className="icon-container-1"  onClick={(i,s)=>{enableDisable(d.deviceid, true)}}>
                                    <FontAwesomeIcon icon={faSquareCheck} />
                                    <span className="icon-container-1-title">Activate
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>}
                               
                            </Col>
                        </Row>

                    </Col>

                })
            }
            </Row>
            {/*Add Event Ticket*/}
            <Modal show={showModal} onHide={handleTerminateModel}>
                <Form onSubmit={handleAddForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Device</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="devicename" onChange={(s)=>{setDeviceName(s.target.value)}}>
                                    <Form.Label>Device Name</Form.Label>
                                    <Form.Control className="clr-black"  type="text" placeholder="Device Name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="deviceimeicode" onChange={(s)=>{setDeviceImeiCode(s.target.value)}}>
                                    <Form.Label>Device IMEI Code</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="Device IMEI Code" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-t-10">
                            <Col>
                                <Form.Group controlId ="devicedescription" onChange={(s)=>{setDeviceDescription(s.target.value)}}>
                                    <Form.Label>Device Description</Form.Label>
                                    <Form.Control as="textarea" rows={4} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Venue</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setVenueId(s.target.value)}}>
                                    <option>Select Venue...</option>
                                    {
                                        venues.map((venue)=>{
                                            return <option value={venue.venueid}>{venue.venuename}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Events</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setEventId(s.target.value)}}>
                                    <option>Select Event...</option>
                                    {
                                        events.map((event)=>{
                                            return <option value={event.eventid}>{event.eventname}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Select User</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setUserId(s.target.value)}}>
                                    <option>Select User...</option>
                                    {
                                        users?.map((user)=>{
                                            return <option value={user.userid}>{user?.login?.username}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Add Device
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*Switch User Modal */}
            <Modal show={showSwithUserModal} onHide={handleTerminateSwitchUserModel}>
                <Form onSubmit={handleSwitchUserForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Switch User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Select User</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setEditUserId(s.target.value)}}>
                                    <option value={editSelectedUser?.userid}>{editSelectedUser?.login?.username}</option>
                                    {
                                        users?.map((user)=>{
                                            return <option value={user.userid}>{user?.login?.username}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSwitchUserModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Switch User
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

             {/*Edit Device*/}
             <Modal show={showEditModal} onHide={handleTerminateEditModel}>
                <Form onSubmit={handleEditForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Update Device</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="devicename" onChange={(s)=>{setEditDeviceName(s.target.value)}}>
                                    <Form.Label>Device Name</Form.Label>
                                    <Form.Control className="clr-black" type="text" value={editDeviceName} placeholder="Device Name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="deviceimeicode" onChange={(s)=>{setEditDeviceImeiCode(s.target.value)}}>
                                    <Form.Label>Device IMEI Code</Form.Label>
                                    <Form.Control className="clr-black" type="text"  value={editDeviceimeicode} placeholder="Device IMEI Code" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-t-10">
                            <Col>
                                <Form.Group controlId ="devicedescription" onChange={(s)=>{setEditDeviceDescription(s.target.value)}}>
                                    <Form.Label>Device Description</Form.Label>
                                    <Form.Control as="textarea"  value={editDeviceDescription} rows={4} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Venue</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setEditVenueId(s.target.value)}}>
                                    <option value={editSelectedVenue?.venueid}>{editSelectedVenue?.venuename}</option>
                                    {
                                        venues.map((venue)=>{
                                            return <option value={venue.venueid}>{venue.venuename}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Events</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setEditEventId(s.target.value)}}>
                                    <option value={editSelectedEvent?.eventid}>{editSelectedEvent?.eventname}</option>
                                    {
                                        events.map((event)=>{
                                            return <option value={event.eventid}>{event.eventname}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>  
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Select User</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setEditUserId(s.target.value)}}>
                                    <option value={editSelectedUser?.userid}>{editSelectedUser?.login?.username}</option>
                                    {
                                        users?.map((user)=>{
                                            return <option value={user.userid}>{user?.login?.username}</option>
                                        })
                                    }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Update Device
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </React.Fragment>
    );
}
export default DeviceInfo;