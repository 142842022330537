import React, {useState} from 'react';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';

import EventManagementNav from '../../components/events/eventmanagementnav';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/esm/Container';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faTicket} from '@fortawesome/free-solid-svg-icons';

//INTERNAL COMPONENTS
import AddVenueContent from '../../components/venues/addvenuecontent';

function AddVenue() {

    const basedata={
        title:'Event Management',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Add Venue'
    }

    return (
        <React.Fragment>
                        <Container fluid>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                        <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Add Venue</Col>
                        <Col lg={3} md={6}><TopRightNav /></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2}> <LeftNav /></Col>
                        <Col lg={11} md={10}><AddVenueContent /></Col>
                    </Row>
                </Col>
            </Container>
        
    </React.Fragment>
    );
}

export default AddVenue;