import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL COMPONENTS
import RestServer from '../cRestServer';
import {orgurl}  from '../../environment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faCalendarPlus, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';

//STORE
import {useRequestStore} from '../../collectionrequestcontext'
import userDataStore from '../../stores/zuserdatastore';

function EditCompanyContent() {

    const history = useNavigate();
    const store = useRequestStore();
    const { userData} = userDataStore();

    //Company Information
    const[companyName, setCompanyName] = useState('');
    const[companyDescription, setCompanyDescription] = useState('');
    const [company, setCompany]=useState({});


    //Company Address
    const[address, setAddress] = useState("");
    const[city, setCity]=useState("");
    const[province, setProvince]=useState("");
    const[postalCode, setPostalCode]=useState("");
    const[country, setCountry]=useState("");

    //Company Contacts
    const [companyContacts, setCompanyContacts]=useState([]);
    const[contactype, setContactype] = useState("");
    const[firstname, setFirstName]=useState("");
    const[lastname, setLastname]=useState("");
    const[emailaddress, setEmailAddress]=useState('');
    const[phone, setPhone]=useState('');

    //Modal Control
    const [showDepartmentContactsModel, setShowDepartmentContactsModel] = useState(false);
    const handleCloseDepartmentContactsModel=()=>{setShowDepartmentContactsModel(false);}

    //CONTACTS
    const[contactvis, setContactvis]=useState("");

    const[tags, setTags]=useState([]);
    const[selectedgroups, setSelectedGroups]=useState([]);

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

   

    //THIS IS USED TO ADD CONTACT INFO FROM MODAL
    const handleAddContactForm=(e)=>{
        e.preventDefault();
        const contact={
            contacttype:contactype,
            firstname:firstname,
            lastname:lastname,
            emailaddress:emailaddress,
            phone:phone

        }
        companyContacts.push(contact);
            setShowDepartmentContactsModel(false);
    }

    const handleUpdateCompany = (e)=>{
        e.preventDefault();

        const companyAddress ={
            address1:address,
            city:city,
            state:province,
            zip:postalCode,
            country:country
        }
        const data={
            companyid:userData[0].companyid,
            companyname:companyName,
            companydescription:companyDescription,
            companyAddress:companyAddress,
            contacts:companyContacts,
            datecreated:company.datecreated,
            isactive:company.isactive,
        }
 
        new RestServer().setUrl(orgurl+'/api/org/editcompany')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                history('/company', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
    }

    useEffect(() => {
        new RestServer().setUrl(orgurl+'/api/org/company/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtncompany=response.data;
            setCompany(rtncompany);
            setCompanyName(rtncompany.companyname);
            setCompanyDescription(rtncompany.companydescription);
            setAddress(rtncompany.companyAddress.address1);
            setCity(rtncompany.companyAddress.city);
            setProvince(rtncompany.companyAddress.state);
            setPostalCode(rtncompany.companyAddress.zip);
            setCountry(rtncompany.companyAddress.country);
        });
    },[]);

    return (
    <React.Fragment>
            {/*}
              <Row className="p-l-50 p-t-25 p-b-25 line-primary-6-bottom-1">
            <Col lg={7} className="font-125 clr-primary-3 font-light">
                Add Venue
            </Col>
        </Row>
    */}

        <Row className="p-t-25 p-l-50 font-100 font-light">
            <Col lg={9}>
                <Row>
                    <Col lg={4} className="p-r-25">
                        <Row className="p-b-25 font-100 font-heavy clr-primary-3">
                            <Col> Company Information</Col>
                        </Row>
                        <Row className="p-t-10 p-b-10 rad-5 bck-clr-primary-5">
                            <Col>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="companyname" onChange={(s)=>{setCompanyName(s.target.value)}}>
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={companyName}  value={companyName}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col>
                                    <Form.Group controlId ="venuedescription" onChange={(s)=>{setCompanyDescription(s.target.value)}}>
                                        <Form.Label>Venue Description</Form.Label>
                                        <Form.Control as="textarea" placeholder={companyDescription}  value={companyDescription} rows={4} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} className="p-r-25">
                        <Row className="p-b-25 font-100 font-heavy clr-primary-3">
                            <Col> Company Address</Col>
                        </Row>
                        <Row className="p-t-10 p-b-10 rad-5 bck-clr-primary-5">
                            <Col>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="address" onChange={(s)=>{setAddress(s.target.value)}}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={address} value={address} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="city" onChange={(s)=>{setCity(s.target.value)}}>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={city} value={city} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="province" onChange={(s)=>{setProvince(s.target.value)}}>
                                        <Form.Label>Province / State</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={province} value={province} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="postalcode" onChange={(s)=>{setPostalCode(s.target.value)}}>
                                        <Form.Label>Postal Code / Zip</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={postalCode} value={postalCode} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="venuecountry" onChange={(s)=>{setCountry(s.target.value)}}>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control className="clr-black" type="text" placeholder={country} value={country} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} className="p-r-25">
                        <Row className="p-b-25 font-100 font-heavy clr-primary-3">
                            <Col>Tags</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25 rad-5 -clr-primary-5">
                            <Col>
                                <Row>
                                    <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                    onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                </Row>
                                <Row className="p-t-25 p-l-25 p-r-25">
                                    {
                                        tags.map((tag)=>{
                                            return  <Col className="bck-clr-primary-3 rad-5 clr-primary-7 m-1 p-3 center">{tag}</Col>
                                        })
                                    }
                                   
                                </Row>  
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* CONTACTS & Tags */}
                <Row className="p-t-25">
                    <Col lg={4} className="p-r-25">
                        <Row className="p-b-25 font-100 font-heavy clr-primary-3">
                            <Col lg={6}>Add Company Contacts</Col>
                            <Col lg={6} className="left" onClick={(s)=>{setShowDepartmentContactsModel(true)}}> <FontAwesomeIcon icon={faPlusSquare} /></Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25 rad-5">
                            <Col className="m-l-25">
                                {
                                    companyContacts.map((contact)=>{
                                        return <Row>
                                        <Col lg={10} className="bck-clr-primary-3 rad-5 p-b-10 p-t-10">
                                            <Row className="center"><Col className="font-300 clr-primary-6"><FontAwesomeIcon icon={faUsersBetweenLines} /></Col></Row>
                                            <Row className="center"><Col className="font-125 clr-primary-6 font-heavy p-b-10">{contact.contacttype}</Col></Row>
                                            <Row><Col className="font-100 clr-primary-6 font-light">{contact.firstname} {contact.lastname}</Col></Row>
                                            <Row><Col className="font-100 clr-primary-6 font-light">Email : {contact.emailaddress}</Col></Row>
                                            <Row><Col className="font-100 clr-primary-6 font-light">Phone : {contact.phone}</Col></Row>
                                            <Row className="p-t-10 center">
                                                <Col className="font-100 clr-primary-4 font-light ">
                                                    <FontAwesomeIcon icon={faCalendarPlus} />
                                                </Col>
                                                <Col className="font-100 clr-primary-4 font-light">
                                                    <FontAwesomeIcon icon={faMobile} />
                                                </Col>
                                                <Col className="font-100 clr-primary-8 font-light">
                                                    <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
                                                </Col>
                                                <Col className="font-100 clr-primary-8 font-light">
                                                    <FontAwesomeIcon icon={faImages} />
                                                </Col>

                                                
                                            </Row>
                                        </Col>
                                    </Row>;
                                    })
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>

            <Col lg={2} className="v-center">
                <Row >
                    <Col lg={12} className="center">
                        <a href="#" className="button-1" onClick={handleUpdateCompany}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Update
                        </a>
                    </Col>
                </Row>
                <Row className="p-t-100">
                    <Col lg={12} className="center">
                        <a href="#" className="button-1">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>

         {/*Add Contact*/}
         <Modal show={showDepartmentContactsModel} onHide={handleCloseDepartmentContactsModel}>
            <Form onSubmit={handleAddContactForm}>
            <Modal.Header closeButton>
            <Modal.Title>Company Contact Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="contacttype" onChange={(s)=>{setContactype(s.target.value)}}>
                            <Form.Label>Contact Type</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder={contactype}  value={contactype} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="firstname" onChange={(s)=>{setFirstName(s.target.value)}}>
                            <Form.Label>Firstname</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder={firstname}  value={firstname} />
                        </Form.Group>
                    </Col>
                    <Col >
                        <Form.Group controlId ="lastname" onChange={(s)=>{setLastname(s.target.value)}}>
                            <Form.Label>Lastname</Form.Label>
                            <Form.Control className="clr-black" type="text" placeholder={lastname}  value={lastname} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control className="clr-black" type="email" placeholder={emailaddress}  value={emailaddress} />
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row className="p-t-10">
                    <Col >
                        <Form.Group controlId ="phonenumber" onChange={(s)=>{setPhone(s.target.value)}}>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control className="clr-black" type="phone" placeholder={phone}  value={phone} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDepartmentContactsModel}>
                Cancel
            </Button>
            <Button variant="primary" type="submit">
                Add Contacts
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>

    </React.Fragment>
    );
}

export default EditCompanyContent;