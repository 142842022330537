import React from 'react';
import { useNavigate } from "react-router-dom";

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faSitemap, faTicket, faLocationDot, faBoxOpen} from '@fortawesome/free-solid-svg-icons';

function EventManagementNav(){
    const history = useNavigate();
    return(
        <React.Fragment>
            <Row className="p-l-50 p-t-100 font-100 clr-primary-4 font-light">
                <Col>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/company', {replace:true})}}>
                                <FontAwesomeIcon icon={faSitemap} /> <span className="p-l-10">Company</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/events', {replace:true})}}>
                                <FontAwesomeIcon icon={faTicket} /> <span className="p-l-10">Events</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/products', {replace:true})}}>
                                <FontAwesomeIcon icon={faBoxOpen} /> <span className="p-l-10">Products</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/venues', {replace:true})}}>
                                <FontAwesomeIcon icon={faLocationDot} /> <span className="p-l-10">Venue</span>
                            </a>
                        </Col>
                    </Row>  
                </Col>
            </Row>
        </React.Fragment>
    );
}
export default EventManagementNav;
