import React, {useState} from 'react';

import {over} from 'stompjs';
import SockJs from 'sockjs-client';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsersViewfinder, faUser, faUserCircle, faSquareEnvelope} from '@fortawesome/free-solid-svg-icons';

function ChatWindow() {

    
    const[userData, setUserData]=useState(null);
    const[publicChat, setPublicChat]=useState(null);
    const[sendMessage, setSendMessage]=useState(null);
    const [stompClient, setStompClient]=useState(null)
    

    const handleChatConnect=()=>{
        let Sock = new SockJs('http://192.168.1.12:8080/ws');
        var sClient = over(Sock);
        setStompClient(sClient);
        sClient.connect({},onConnected, onError);

    }
    const onConnected=()=>{
        console.info("connected");
        stompClient.subscribe('/chatroom/public', onPublicMessageReceived);
    }

    const onPublicMessageReceived = (payload)=>{
        let payLoadData=JSON.parse(payload.body);
        publicChat.push(payLoadData);
        setPublicChat([...publicChat]);
    }

    const onError=(err)=>{
        console.log(err);
    }

    const handleChatSend=()=>{
        console.info("sending the following message");
        
        const msgout={
            message:sendMessage 
        };
        console.info(msgout);

        stompClient.send("/chatroom/public",{}, sendMessage)

    }

    return (
        <React.Fragment>
            <Col className="bck-clr-primary-5 rad-5">
                <Row>
                    <Col className="bck-clr-primary-6 p-t-10 p-b-10 font-size-75" lg={2}>
                        <Row className="p-b-10 font-heavy font-size-100"><Col>  <FontAwesomeIcon icon={faUsersViewfinder} />&nbsp;&nbsp;Channels</Col></Row>
                        <Row><Col className="font-heavy"># Event 1</Col></Row>
                        <Row><Col># Event 2</Col></Row>

                        <Row className="p-b-10 p-t-25 font-heavy font-size-100"><Col>  <FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;Direct Message</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;Jay</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;Sam</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;Tom</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;John</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;Jolene</Col></Row>
                        <Row><Col><FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;Chase</Col></Row>
                    </Col>
                    <Col className="bck-clr-primary-6 m-l-10 h-750" lg={9}>
                       
                        <Row className="p-t-10 ">
                            <Col lg={11} className="font-size-75">
                                <Row ><Col><span className="font-heavy">Jay Lushinton</span> &nbsp; &nbsp; <span className="font-light">2:50AM</span></Col></Row>
                                <Row><Col>i see i will review, bit i see do not understand where you have put the validation from addCaompany</Col></Row>
                            </Col>
                        </Row>
                        <Row className="p-t-10 ">
                            <Col lg={11} className="font-size-75">
                                <Row ><Col><span className="font-heavy">Jay Lushinton</span> &nbsp; &nbsp; <span className="font-light">2:50AM</span></Col></Row>
                                <Row><Col>i see i will review, bit i see do not understand where you have put the validation from addCaompany</Col></Row>
                            </Col>
                        </Row>
                        <Row className="p-t-10 ">
                            <Col lg={11} className="font-size-75">
                                <Row ><Col><span className="font-heavy">Jay Lushinton</span> &nbsp; &nbsp; <span className="font-light">2:50AM</span></Col></Row>
                                <Row><Col>i see i will review, bit i see do not understand where you have put the validation from addCaompany</Col></Row>
                            </Col>
                        </Row>

                        

                    </Col>
                </Row>
                <Row>
                    <Col className="bck-clr-primary-6 p-t-10 p-b-10 font-size-75" lg={2}>
                        
                    </Col>
                    <Col className="bck-clr-primary-6 m-l-10" lg={9}>
                    <Row className="p-t-25 al">
                            <Col className="bck-clr-primary-7 m-2
                            line-primary-4-bottom-1
                            line-primary-4-top-1
                            line-primary-4-left-1
                            line-primary-4-right-1
                            rad-3
                            p-10
                            "> 
                           <Form.Group controlId ="messageChat" onChange={(s)=>{setSendMessage(s.target.value)}}>
                            
                            <Form.Control as="textarea" rows={4} />
                        </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="center">
                                <a href="#" className="button-1" onClick={handleChatConnect}>
                                &nbsp;&nbsp; connect
                                </a>
                            </Col>
                            <Col lg={6} className="center">
                                <a href="#" className="button-1" onClick={handleChatSend}>
                                &nbsp;&nbsp; send
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row><Col lg={3}></Col><Col>
               
                </Col>
                <Col >
                </Col>
                </Row>
            </Col>

        </React.Fragment>
    );
}

export default ChatWindow;