import React, {useState} from 'react';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';

import EventManagementNav from '../../components/events/eventmanagementnav';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faTicket} from '@fortawesome/free-solid-svg-icons';

//INTERNAL COMPONENTS
import EditCompanyContent from '../../components/company/editcompanycontent';

function EditCompany() {

    const basedata={
        title:'Event Management',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Edit Company'
    }

    return (
        <React.Fragment>
        <Row>
            <Col lg={12} className="bck-clr-primary-7">
                <TopNav data = {basedata}/>

                <Row>
                    <Col lg={2} className="bck-clr-primary-3 height-100"><EventManagementNav /></Col>
                    <Col lg={10}>
                       
                        <BreadCrumbs data = {basedata}/>
                        <EditCompanyContent/>
                        
                      
                    </Col>
                </Row>
            </Col>
        </Row>
    </React.Fragment>
    );
}

export default EditCompany;