import React, {useState} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';

import CompanyManagementNav from '../../components/company/companymanagementnav';

import CompanyInfo from '../../components/company/companyinfo';
import CategoryInfo from '../../components/company/categoryinfo';
import TaxInfo from '../../components/company/taxinfo';



//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';


function CompanyDashboard() {

    const basedata={
        title:'Home Dashboard',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Company'
    }

    return (
        <React.Fragment>
             <Container fluid>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                        <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Company Management</Col>
                        <Col lg={3} md={6}><TopRightNav /></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2}> <LeftNav /></Col>
                        <Col lg={11}  md={10}><CompanyInfo /></Col>
                    </Row>
                   
                </Col>
            </Container>
        </React.Fragment>
    );

}

export default CompanyDashboard;