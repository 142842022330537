import React from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';

import CompanyManagementNav from '../../components/company/companymanagementnav';
import CompanyInfo from '../../components/company/companyinfo';
import ChatWindow from '../../components/chat/chatwindow';
import ChatRoom from '../../components/chat/chatroom';

//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';

function ChatDashboard() {

    const basedata={
        title:'Home Dashboard',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Chat Services'
    }

    return (
        <React.Fragment>
              <Row>
                <Col lg={12} className="bck-clr-primary-7">
                    <TopNav data = {basedata}/>
                    <Row>
                        <Col lg={2} className="bck-clr-primary-3 height-100"><CompanyManagementNav /></Col>
                        <Col lg={10}>
                            <BreadCrumbs data = {basedata}/>
                            <Row className="p-t-10 p-l-50 p-r-50">
                                <CompanyInfo />
                            </Row>
                            <Row className="p-t-10 p-l-50 p-r-50">
                                <ChatWindow />
                                {/*<ChatRoom />*/}
                            </Row>
                        </Col>
                    </Row>
                </Col>
              </Row>
        </React.Fragment>
    );
}

export default ChatDashboard;