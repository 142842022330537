import React, {useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


function CustomDropDown(props){
    console.info("CustomDropDown")

    const handleChange=(e)=>{
        console.info(e);
        props.cbfun(e);
    }

    return(
        <React.Fragment>
            <Row>
                <Col lg={3}>{props.title}</Col>
                <Col>
                    <Form.Control as="select" onChange={(s)=>{handleChange(s.target.value)}}  className="font-size-75 font-heavy8">
                        <option>{props.initdd}</option>
                        
                        {
                            props.data?.map((d)=>{return <option value={d.value}>{d.key}</option>})
                        }
                    </Form.Control>
                </Col>
            </Row>
          
            
            
        </React.Fragment>
    );

}
export default CustomDropDown;