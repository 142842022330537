import React from 'react';

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function BreadCrumbs(props){
    return(
        <React.Fragment>
        <Row className="line-primary-6-bottom-1 p-t-25 p-l-25 p-b-25 font-100">
            <Col lg={12}>
               <span className="p-l-25">{props.data.breadcrumb} </span> 
            </Col>
            
        </Row>
    </React.Fragment>
    )

}
export default BreadCrumbs;