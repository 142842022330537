import create from 'zustand';
import { persist } from 'zustand/middleware';

/*
const useStore = create((set)=>({
        userData:[{companyname:""}],
        addUserData:(payload)=>set((state)=>({userData:[payload,...state.userData]})),
}));
*/
/*
const useStore = create((set) => ({
    count: 1,
    inc: () => set((state) => ({ count: state.count + 1 })),
  }))
  */
/*
const useStore=create(
    persist(
        (set,get)=>({
            count: 1,
            inc: () => set((state) => ({ count: state.count + 1 })),
        })
    )
);
*/

const userDataStore=create(
    persist(
        (set,get)=>({
            userData: [],
            addUserData:(payload)=>set((state)=>({userData:[payload,...state.userData]})),
            clearUserData:()=>set(()=>({userData:[]})),
        })
    )
);

export default userDataStore;