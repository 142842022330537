import React,{useState, useEffect} from 'react';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';

import ProductDashboardContent from '../../components/products/productdashboardcontent';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

function ProductDashboard() {
    return (
        <React.Fragment>
            <Container fluid>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                        <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Event Dashboard</Col>
                        <Col lg={3} md={6}><TopRightNav /></Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="p-t-25">
                        <Col lg={1} md={2}> <LeftNav /></Col>
                        <Col lg={11} md={10}><ProductDashboardContent /> </Col>
                    </Row>
                </Col>
            </Container>
        </React.Fragment>

    );
}
export default ProductDashboard;