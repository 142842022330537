import React from 'react';
import { useNavigate } from "react-router-dom";

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faSitemap, faTicket, faLocationDot, faMessage} from '@fortawesome/free-solid-svg-icons';

function CompanyManagementNav(){
    const history = useNavigate();
    return(
        <React.Fragment>
            <Row className="p-t-50 font-100 clr-primary-7 font-light">
                <Col>
                    <Row className="p-b-10">
                        <Col className="p-l-25 font-125 font-light">
                            Company Management
                        </Col>
                    </Row>

                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/company', {replace:true})}}>
                                <FontAwesomeIcon icon={faSitemap} /> <span className="p-l-10">Company Info</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/users', {replace:true})}}>
                                <FontAwesomeIcon icon={faTicket} /> <span className="p-l-10">Users</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/device', {replace:true})}}>
                                <FontAwesomeIcon icon={faLocationDot} /> <span className="p-l-10">Devices</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="p-b-10">
                        <Col>
                            <a href="#" className="snbutton-1" onClick={(s)=>{history('/chatservices', {replace:true})}}>
                                <FontAwesomeIcon icon={faMessage} /> <span className="p-l-10">Chat Services</span>
                            </a>
                        </Col>
                    </Row>  

                    
                </Col>
            </Row>

        </React.Fragment>
    );
}
export default CompanyManagementNav;
