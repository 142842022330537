import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import {QrScanner} from '@yudiel/react-qr-scanner';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import RestServer from '../../components/cRestServer';
import {producturl, conurl}  from '../../environment';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMinusCircle, faTicketAlt } from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';


function VendorPosSystem() {

    //STORE SYSTEMS
    const { userData} = userDataStore();

   

    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState('No result');

    const [products, setProducts]=useState(null);
    const[cartProducts, setCartProducts]=useState([]);

    const[initScanModel, setInitScanModel]=useState(false);

    const handleCloseModel=()=>{
        setInitScanModel(false);

    }

    const addToCart=(prod)=>{
        var cart=[];
       
        if(cartProducts.length===0){
            cart.push({
                product:prod,
                quantity:1
            });
            setCartProducts(cart);
        }else{
        cartProducts.map((p)=>{
            if(p.product.productId===prod.productId){
                cart.push({
                    product:prod,
                    quantity:p.quantity+1
                });
            }else{
                cart.push({
                    product:prod,
                    quantity:1
                });
            }
        });
        setCartProducts(cart);
      }
    }

    const DisplayImage=(prod)=>{
        if(prod.category.categorycode==="VENDORPRODUCT"){
            const image = prod?.images[0]?.imageloc+prod?.images[0]?.imagename;
            return <img src={image}  className="img-container-1-pos rad-tl-5 rad-tr-5 rad-bl-5 rad-br-5 m-1"/>
        }else{
            return "";
        }
    }

    const incCart=(e)=>{
        console.info(e);

        var cart=[];
        cartProducts.map((p)=>{
            if(p.product.productId===e.product.productId){
                cart.push({
                    product:e.product,
                    quantity:p.quantity+1
                });
            }else{

                cart.push({
                    product:e.product,
                    quantity:p.quantity
                });
            }
        });
        setCartProducts(cart);  
    }
    
    const decCart=(e)=>{
        var cart=[];
        cartProducts.map((p)=>{
            if(p.product.productId===e.product.productId){
                var qu=0;
                if(p.quantity==0){
                    qu=0; 
                }else{
                    qu=p.quantity-1;
                }
                cart.push({
                    product:e.product,
                    quantity:qu
                });
            }else{

                cart.push({
                    product:e.product,
                    quantity:p.quantity
                });
            }
        });
        setCartProducts(cart);
    }
    const tokenCal=()=>{
        var total=0;

        cartProducts.map((p)=>{
            total=(total+(p.product.price*p.quantity));
        });
        return total;
    }

    const processEndUserToken=(resString)=>{
        const res=JSON.parse(resString);

        if(res.ticketType==="VNDPROD"){

            const dataSnt={
                endUserId:res.userId,
                productId:res.productId
            }

            new RestServer().setUrl(conurl+'/api/cms/getvendortokens')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(dataSnt)
            .connect()
            .then(response=>{
                var vendortokens=[];

                response.data.map((r)=>{
                    vendortokens.push(r);
                });

                const lstToken0 = vendortokens.slice(-1);
                const lstToken= lstToken0[0];

                if(lstToken.currentTokenCount>=tokenCal()){

                    const nwTokenCount=lstToken.currentTokenCount - tokenCal();

                    const nwToken={
                        previousVendorProductTokenId:lstToken.vendorProductTokenId,
                        productId:lstToken.productId,
                        orderId:lstToken.orderId,
                        endUserId:lstToken.endUserId,
                        initialTokenCount:lstToken.initialTokenCount,
                        purchaseTokenCount:tokenCal(),
                        currentTokenCount:nwTokenCount
                    }

                    new RestServer().setUrl(conurl+'/api/cms/processvendortoken')
                    .setMethod('POST')
                    .flagReturnData(true)
                    .setPostBody(nwToken)
                    .connect()
                    .then(response=>{
                        const rtndata =response.data;
                        if(rtndata.messageTypeID===1){
                            setInitScanModel(false);
                            toast("Product has been executed successfully");
                            setCartProducts([]);
                        }else{
                            setInitScanModel(false);
                            toast(rtndata.Message);
                        }
                    });
                }else{
                    setInitScanModel(false);
                    toast("Not Enough Tokens. User only has "+lstToken.currentTokenCount+" but requires "+ tokenCal());
                    setCartProducts([]);
                }
            });

        }else{
            alert("ticketed does not match");
            setInitScanModel(false);
            toast("Wrong Ticket Type");

        }
        
        
    }

    useEffect(() => {      
        new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const eventProducts=response.data;
            var tempEventProducts=[];

            eventProducts.map((ep)=>{
                if(ep.category.categorycode==="VENDORPRODUCT"){

                    if(ep?.additionalVendorInfo?.vendorId===userData[0].vendorId){
                        tempEventProducts.push(ep)
                    }
                }
            });
            setProducts(tempEventProducts);
        });
    },[]);

    return (
        
        <React.Fragment>
             
            <Row className=" p-b-25">
                <Col lg={5} md={4}>
                {
                    products?.map((product)=>{
                        return <Col lg={2} >
                            <Row>
                                <Col onClick={()=>{addToCart(product)}}>
                                    {DisplayImage(product)}
                                </Col>
                            </Row>
                        </Col>
                    })
                }
                </Col>
                <Col lg={5} md={6}>
                    {
                        cartProducts.map((c)=>{
                            return <Row>
                                <Col lg={6} className="font-size-100 p-t-10">
                                    {c?.product.productName}
                                </Col>
                                <Col className="font-size-75 font-heavy ">
                                    <button className="container-1-25 font-size-75 font-heavy9" onClick={()=>{incCart(c)}}>+</button> 
                                        &nbsp; {c.quantity} &nbsp; 
                                    <button className="container-1-25 font-size-75 font-heavy9" onClick={()=>{decCart(c)}}>-</button> 
                                </Col>
                                <Col className="p-t-10">
                                    <span ><FontAwesomeIcon icon={faMinusCircle}  /></span>
                                </Col>
                            </Row>
                        })
                    }
                </Col>
                <Col lg={5} md={10}>
                    <Row className="p-b-25 p-t-50">
                        <Col className="font-size-150 font-heavy8"> Order Summary</Col>
                    </Row>
                    <Row>
                        <Col className="container-1 font-size-100 font-heavy">
                            <Row className="p-t-25">
                                <Col className="p-l-25">Subtotal</Col>
                                <Col className="right p-r-50">${tokenCal()}</Col>
                            </Row>
                            
                            <Row className="p-t-25 font-heavy9 p-b-25">
                                <Col className="p-l-25">Total</Col>
                                <Col className="right p-r-50">${tokenCal()}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="p-t-25 p-b-25">
                        <Col><button className="icon-text-container-2 font-size-125 font-heavy" onClick={()=>{setInitScanModel(true)}}>Proceed To Checkout</button></Col>
                    </Row>
                </Col>
            </Row>


             {/*Add Company Taxes*/}
            <Modal show={initScanModel} onHide={handleCloseModel}>
            <Form >
            <Modal.Header closeButton>
            <Modal.Title>Process Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    
                <QrScanner
          onDecode={(result) => processEndUserToken(result)}
          onError={(error) => alert(error?.message)}
      />
                </Row>
             
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" >
                Cancel
            </Button>
           
            </Modal.Footer>
            </Form>
        </Modal>

        </React.Fragment>
    );
}
export default VendorPosSystem;