import React, {useState} from 'react';
import {XYPlot, LineSeries, XAxis, VerticalGridLines, YAxis} from 'react-vis';

import EventDataBarChart from '../datawidget/charts/EventDataBarChart';



//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function VenueDashboardContent() {

  

    return (
        <React.Fragment>
            <Row className="">
                <EventDataBarChart />
               
                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Ticket Checked In</Col>
                    </Row>
                    <Row className="p-t-25 font-size-250 font-heavy9 p-l-10 p-r-10 center">
                        <Col>100 / 150</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Ticket Checked In</Col>
                    </Row>
                    <Row className="p-t-25 font-size-250 font-heavy9 p-l-10 p-r-10 center">
                        <Col>100 / 150</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Ticket Checked In</Col>
                    </Row>
                    <Row className="p-t-25 font-size-250 font-heavy9 p-l-10 p-r-10 center">
                        <Col>100 / 150</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
  
                
            </Row>
        </React.Fragment>
    );
}
export default VenueDashboardContent;