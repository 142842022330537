import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';

//INTERNAL
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/*TEMP Delete */
import complogo from './logo.png';

//FONT ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandHoldingDollar, faEdit, faUser, faChartPie, faTrash, faPlusCircle, faUtensilSpoon} from '@fortawesome/free-solid-svg-icons';

//STORE
import userDataStore from '../../stores/zuserdatastore';


function VendorList() {

    //STORES
    const { userData} = userDataStore();

    const history = useNavigate();

    const [vendors, setVendors]=useState([]);
    const[selectedVendor, setSelectedVendo]=useState({});
    const[showModal, setShowModal]=useState(false);
    

    const[emailAddress, setEmailAddress]=useState(null);
    const[username, setUsername]=useState(null);
    const[password, setPassword]=useState(null);
    const[userType, setUserType]=useState("EV");
    const[vendorId, setVendorId]=useState('');

    const[users, setUsers]=useState([]);


    const handleTerminateModel=()=>{setShowModal(false);}


    const renderVendors=()=>{

        if(userType==="EV"){
            return  <Row className="p-t-10">
                <Col lg={12}>  
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setVendorId(s.target.value)}}>
                            <option value={selectedVendor?.vendorid}>{selectedVendor?.vendorname}</option>
                            
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        }
    }

    const handleAddVendorUser=(vendor, showVendorModelBool)=>{
        setSelectedVendo(vendor);
        setVendorId(vendor.vendorid)
        setShowModal(showVendorModelBool);
    }

    const vendorAccessCheck=()=>{
        console.info("vendorAccessCheck");
        console.info(userData);
        if(userData[0].usertype==="EV"){
            return true;
        }else{
            return false;
        }
    }

    const handleAddForm = ()=>{
        var data ={};

        if(userType==="EV"){
            data={
                companyId:userData[0].companyid,
                signupType:userType,
                vendorId:vendorId,
                email:emailAddress,
                username: username, 
                password: password
            }

        }else{
            data={
                companyId:userData[0].companyid,
                signupType:userType,
                email:emailAddress,
                username: username, 
                password: password
            }
        }
       
        new RestServer().setUrl(orgurl+'/api/org/usersign')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            console.info(response.data)
            toast(response.data.message);
            setShowModal(false);
            
            new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    const rtn=response.data;
                    setUsers(rtn);   
                });
                
            
        });
    }


   

   

    const routeClick=(path)=>{
        history(path, {replace:true})
   }

   const delVendor=(v)=>{

    new RestServer().setUrl(orgurl+'/api/org/vendor/'+v.vendorid)
    .setMethod('DELETE')
    .flagReturnData(true)
    .connect()
    .then(response=>{
        new RestServer().setUrl(orgurl+'/api/org/companyvendor/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{

            var vens=[];
            const data=response.data;

            if(userData[0].usertype==="EV"){
                data.map((v)=>{
                    if(userData[0].vendorId===v.vendorid){
                        vens.push(v);
                    }
                })
            }else{
                vens=data;
            }

            setVendors(vens);
        });
    });

   }

    useEffect(() => {

        new RestServer().setUrl(orgurl+'/api/org/companyvendor/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{

            var vens=[];
            const data=response.data;

            if(userData[0].usertype==="EV"){
                data.map((v)=>{
                    if(userData[0].vendorId===v.vendorid){
                        vens.push(v);
                    }
                })
            }else{
                vens=data;
            }
            console.info(vens)

            setVendors(vens);
        });

    },[]);

    return (
        <React.Fragment>
            <Row className="p-b-25">
            <Col className="right p-r-50">
                 <button className="icon-text-container-0 m-l-100 font-heavy font-size-75 p-r-20" onClick={()=>{routeClick("/addvendor")}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Add
                </button>
                 </Col>
            </Row>
            {
                vendors.map((vendor)=>{
                    return  <Row>
                    <Col lg={10} md={10} className="glass-container-1 m-10">
                        <Row>
                            <Col lg={1} className="p-t-10 p-b-10"><img src={vendor?.vendorimage[0]?.imageloc+vendor?.vendorimage[0]?.imagename}  className="img-container-circle-100 "/></Col>
                            <Col className="p-l-50">
                                <Row className="p-t-10">
                                    <Col className="font-size-125 font-heavy8">{vendor.vendorname}</Col>
                                </Row>
                                <Row className="p-t-10">
                                    <Col className="font-size-75">{vendor.vendordescription}</Col>
                                </Row>
                            </Col>
                            <Col  lg={3} >
                                <Row className="p-t-25 p-b-25">
                                    <Col>
                                       <button className="icon-container-1" onClick={()=>{routeClick("/editvendor/"+vendor.vendorid)}}>
                                           <FontAwesomeIcon icon={faEdit} />
                                           <span className="icon-container-1-title">Edit
                                               <div className="icon-container-1-title-pointer"></div>
                                           </span>
                                       </button>
                                   </Col>
                                   <Col>
                                       <button className="icon-container-1" onClick={()=>{delVendor(vendor)}}>
                                           <FontAwesomeIcon icon={faTrash} />
                                           <span className="icon-container-1-title">Delete
                                               <div className="icon-container-1-title-pointer"></div>
                                           </span>
                                       </button>
                                   </Col>
                                   <Col>
                                       <button className="icon-container-1" onClick={()=>{routeClick("/vendors")}}>
                                           <FontAwesomeIcon icon={faChartPie} />
                                           <span className="icon-container-1-title">Charts
                                               <div className="icon-container-1-title-pointer"></div>
                                           </span>
                                       </button>
                                   </Col>
                                   <Col>
                                       <button className="icon-container-1" onClick={()=>{handleAddVendorUser(vendor,true)}}>
                                           <FontAwesomeIcon icon={faUser} />
                                           <span className="icon-container-1-title">Users
                                               <div className="icon-container-1-title-pointer"></div>
                                           </span>
                                       </button>
                                   </Col>
                                   <Col>
                                       <button className="icon-container-1" onClick={()=>{routeClick("/vendors")}}>
                                           <FontAwesomeIcon icon={faUtensilSpoon} />
                                           <span className="icon-container-1-title">Food Menu
                                               <div className="icon-container-1-title-pointer"></div>
                                           </span>
                                       </button>
                                   </Col>
    
                                   
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                 </Row>
                })
            }


             {/*Add User Model*/}
             <Modal show={showModal} onHide={handleTerminateModel}>
                
                    <Modal.Header closeButton>
                    <Modal.Title>Add Vendor User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                        {((vendorAccessCheck())?<Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option >Select User Type...</option>
                                            <option value="EV">Event Vendor User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>: <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option value="EV">Event Vendor User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>)}
                               
                            </Row>
                            {renderVendors()}
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="username" onChange={(s)=>{setUsername(s.target.value)}}>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="Username" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setPassword(s.target.value)}}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control className="clr-black" type="password" placeholder="Password" />
                                </Form.Group>
                            </Col>
                        </Row> 
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={()=>{handleAddForm()}}>
                        Create User
                    </Button>
                    </Modal.Footer>
            </Modal>
            
        </React.Fragment>
    );
}
export default VendorList;