'use strict';
import moment from 'moment';

export  const dateFormat={

    futureEvent:function(eventtime){
        const cTime=moment().unix()*1000;
        var rtn=false;
        if(cTime<eventtime.startDate){
            rtn=true;
        }
        return rtn
    },
    pastEvent:function(eventtime){
        const cTime=moment().unix()*1000;
        var rtn=false;
        if(cTime>eventtime.endDate){
            rtn=true;
        }
        return rtn

    },

   
    eventStarted:function(eventrange){
        const eTime=moment().unix()*1000;
        const threeHours= 3*3600000;//three hours
        const cTime=eTime+threeHours;//current time + 3 hours

        var rtn=false;
        if(eventrange.startDate <= eTime && eventrange.endDate>=cTime){
            rtn=true; 
        }
        return rtn;

    },

    getformateddate: function(epoch){

        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('dddd MMMM Do YYYY, h:mm:ss a');
    },
    getd:function(d){
        return d.day;
    },
    getdd:function(d){
        let r;
        if(d.day.toString().length===1){
            r="0"+d.day;
        }else{
            r=d.day;
        }
        return r;
    },
    getDateLong: function(d){
        const date = new Date();
        date.setDate(d.day -1);
        console.info(date.getDay())
        return date.toLocaleString('en-US');

    },

    formatDate:function(epoch, format){
        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('ddd');

    },
    getEpochFromData:function(e){
        return new Date(e).valueOf();
    }
    

};
/*
export const dateFormat=(jsonDate)=>{

    d:jsonDate,

    getRawDate: ()=>{
        return d.day
    };

    
}
*/