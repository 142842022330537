function base(){
    const baseurl="https://192.168.68.56:1110";
    return baseurl;
}

//export const orgurl="http://192.168.68.56:8600"; //LOCAL
//export const orgurl="http://localhost:8600"; //LOCAL
export const orgurl="https://alb.evntxz.com:8600"; //AWS

//export const apiurl="http://192.168.68.56:1110";//LOCAL
//export const apiurl="http://localhost:1110";//LOCAL
export const apiurl="https://alb.evntxz.com:1110"; //AWS

//export const commurl="http://192.168.68.56:1130";//LOCAL
//export const commurl="http://localhost:1130";//LOCAL
export const commurl="https://alb.evntxz.com:1130"; //AWS


//export const accpakurl="http://192.168.68.56:1140/api/apm";//LOCAL
//export const accpakurl="http://localhost:1140/api/apm";//LOCAL
export const accpakurl="https://alb.evntxz.com:1140/api/apm"; //AWS

//export const billcapurl="http://192.168.68.56:1150"; //LOCAL
//export const billcapurl="http://localhost:1150"; //LOCAL
export const billcapurl="https://alb.evntxz.com:1150"; //AWS

//export const conurl="http://192.168.68.56:8100"
//export const conurl="http://localhost:8100"
export const conurl="https://alb.evntxz.com:8100"; //AWS

//export const producturl ="http://192.168.68.56:8500"
//export const producturl ="http://localhost:8500"
export const producturl="https://alb.evntxz.com:8500"; //AWS

export default base;