import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

//STORES
import {useRequestStore} from '../collectionrequestcontext';
import userDataStore from '../stores/zuserdatastore';

//INTERNAL
import RestServer from '../components/cRestServer';
import {conurl,orgurl}  from '../environment';



function LoginRedirect(){
    const history = useNavigate();
    const store = useRequestStore();
    const {addUserData, clearUserData} = userDataStore();

    useEffect(() => {
        
        const queryString = window.location.hash;

        const urlParams = new URLSearchParams(queryString);
        const urltoken=urlParams.get('#id_token');

        if(urltoken ===null){
            //redirect them to home screen
            history("/", {replace:true})

        }else{

            const data={
                token:urltoken,
            }

            new RestServer().setUrl(orgurl+'/api/org/usertoken')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(data)
            .connect()
            .then(response=>{
                const rtndata =response.data;

                console.info("rtndata: ");
                console.info(rtndata);
                if(rtndata.active === true){
                   
                    if(rtndata.usertype==="EV"){
                        const now = new Date()  
                        const userinfo={
                            companyid:rtndata.companyid,
                            userid:rtndata.userid,
                            usertype:rtndata.usertype,
                            vendorId:rtndata.vendorId,
                            access_token:rtndata.token.token,
                            refresh_expires_in:rtndata.token.exp,
                            refresh_token:rtndata.token.nbf,
                            expires_in:rtndata.token.exp,
                            current_time:Math.round(now.getTime() / 1000)
        
                        };
                        clearUserData();
                        addUserData(userinfo);
                       history('/vendors', {replace:true})
                    }else{

                        const now = new Date()  
                        const userinfo={
                            companyid:rtndata.companyid,
                            userid:rtndata.userid,
                            usertype:rtndata.usertype,
                            vendorId:"",
                            access_token:rtndata.token.token,
                            refresh_expires_in:rtndata.token.exp,
                            refresh_token:rtndata.token.nbf,
                            expires_in:rtndata.token.exp,
                            current_time:Math.round(now.getTime() / 1000)
        
                        };
                        clearUserData();
                        addUserData(userinfo);
                        history('/events', {replace:true})
                    }
                   
            
                }else{
                    toast("Login failed..please try again")
                }
    
            },[]);
            
        }
    },[]);
}

export default LoginRedirect;