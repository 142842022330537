import React, {useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ImageUpload(props){

    const[imagename, setImagename] = useState('');
    const[imagedescription, setImagedescription] = useState('');
    const[imageloc, setImageloc] = useState('');
    const[imagesize, setImagesize] = useState('');
    const [vimages, setVimages]=useState([]);
    const[imageRequired, setImageRequired]=useState(false);
    

    const onDrop=(e)=> {
        //console.info(e);
        let files =e.target.files;
        //console.info(files);
        let reader = new FileReader();
       reader.readAsDataURL(files[0]);
       reader.onloadend = function() {
           var base64data = reader.result;
           const imagedata ={
            imagename: files[0].name,
            imageloc:base64data,
            imagesize:files[0].size,
            imagetype:files[0].type
            }
            //console.info(imagedata);
            const newImageState = [...vimages, imagedata];
            //console.info("newImageState");
           // console.info(newImageState);
           if(newImageState.length>0){setImageRequired(true)}
            setVimages(newImageState);
            localStorage.setItem("imagedatastate", JSON.stringify(newImageState));
       }
    }
    const onClickDelete=(i)=>{
        console.info("delete image");

        var eventdates=[];
        vimages.map((idata, index) =>{
            if(i !==index){
                eventdates.push(idata);
            }
        });
        if(eventdates.length>0){setImageRequired(true)}
        setVimages(eventdates);
    }

    useEffect(() => {
        console.info("DateSelector->useEffect :");
        console.info(props)
        /*
        props.data.map((image)=>{
            const imagedata ={
                imagename: image.imagename,
                imageloc:image.imageloc+""+image.imagename,
                imagesize:image.size,
                imagetype:image.type
            }
            const newImageState = [...vimages, imagedata];
            setVimages(newImageState);

        })
        //setTimeDate(props.data);
        */
    },[]);



   


    return(
        <React.Fragment>
            <Row>
                <Col>
                    <span className="font-size-75 clr-1">* &nbsp;&nbsp;&nbsp;</span>
                    <input type="file" name="file" onChange={(e)=>{onDrop(e)}}/>
                    {imageRequired?'':<span className="font-size-75 clr-1">&nbsp; &nbsp;Please Add Image</span>}
                </Col>
            </Row>
            <Row>
                <Col>
                {
                    vimages?.map((image, index) =>{
                        return <Row key={index} className="p-t-10 p-l-10 hoverpos">
                            <span className="remImage p-t-10" id="delete"></span>
                            <img src={image.imageloc}
                                    className="p-t-10 img-container-2"
                                    onClick={()=>{onClickDelete(index)}}
                                    />
                        </Row>
                    })
                }
                </Col>
            </Row>
          

        </React.Fragment>
    );
}

export default ImageUpload;