import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL COMPONENTS
import RestServer from '../cRestServer';
import {orgurl}  from '../../environment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faCalendarPlus, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';

//STORE
import {useRequestStore} from '../../collectionrequestcontext'
import userDataStore from '../../stores/zuserdatastore';

function EditVenueContent() {

    const history = useNavigate();
    const store = useRequestStore();
    const { userData} = userDataStore();

    //Venue Information
    const[venueId, setVenueId]=useState(null);
    const[venue, setVenue]=useState(null);
    const[venueName, setVenuename] = useState('');
    const[venueDescription, setVenuedescription] = useState('');
    const[venueContactNumber, setVenueContactNumber]=useState("");
    const[venueEmailAddress, setVenueEmailAddress]=useState("");

    //Venue Address
    const[venueAddress, setVenueAddress] = useState("");
    const[venueCity, setVenueCity]=useState("");
    const[venueProvince, setVenueProvince]=useState("");
    const[venuePostalCode, setVenuePostalCode]=useState("");
    const[venueCountry, setVenueCountry]=useState("");

    //Modal Control
    const [showDepartmentContactsModel, setShowDepartmentContactsModel] = useState(false);
    const handleCloseDepartmentContactsModel=()=>{setShowDepartmentContactsModel(false);}

    //Venue Rooms
    const [venueRooms, setVenueRooms]=useState([]);
    const[venueRoomName, setVenueRoomName]=useState("");
    const[venueRoomDescription, setVenueRoomDescription]=useState("");
    const[venueRoomCapacity, setVenueRoomCapacity]=useState(0);
  

     //CONTACTS
     const[firstname, setFirstName]=useState("");
     const[lastname, setLastname]=useState("");
     const[emailaddress, setEmailAddress]=useState('');
     const[phone, setPhone]=useState('');
     const[contactvis, setContactvis]=useState("");

    const[tags, setTags]=useState([]);
    const[selectedgroups, setSelectedGroups]=useState([]);

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

    //THIS IS USED TO ADD THE VENUE ROOMS FROM MODAL
    const handleAddVenueRoomForm =(e)=>{
        e.preventDefault();
        if(venueRoomCapacity===""){
            setVenueRoomCapacity(0)
        }
        if(venueRoomCapacity===null){
            setVenueRoomCapacity(0)
        }
        if(venueRoomCapacity===undefined){
            setVenueRoomCapacity(0)
        }
        const venueroom={
            venueRoomName:venueRoomName,
            venueRoomDescription:venueRoomDescription,
            venueRoomCapacity:venueRoomCapacity

        }
        venueRooms.push(venueroom);
        setVenueRoomName("");
        setVenueRoomDescription("");
        setVenueRoomCapacity(0);
    }

    //THIS IS USED TO ADD CONTACT INFO FROM MODAL
    const handleAddContactForm=(e)=>{
        e.preventDefault();
        console.info("handleFormSubmit");

        const contactvisual = <Row>
                <Col lg={10} className="bck-clr-primary-3 center rad-5 p-b-10 p-t-10">
                    <Row><Col className="font-300 clr-primary-6"><FontAwesomeIcon icon={faUsersBetweenLines} /></Col></Row>
                    <Row><Col className="font-125 clr-primary-6 font-heavy">{firstname} {lastname}</Col></Row>
                    <Row><Col className="font-100 clr-primary-6 font-light">{emailaddress}</Col></Row>
                    <Row><Col className="font-100 clr-primary-6 font-light">{phone}</Col></Row>
                </Col>
            </Row>;
            setContactvis(contactvisual);
            setShowDepartmentContactsModel(false);
    }

    const handleUpdateVenue = (e)=>{
        e.preventDefault();
        console.info("handleUpdateVenue: ")
        console.info(store.userdata);
        console.info(userData);

        const data={
            companyid:userData[0].companyid,
            venueid:venueId,
            venuename:venueName,
            venuedescription:venueDescription,
            venueaddress:{
                address1:venueAddress,
                city:venueCity,
                state:venueProvince,
                zip:venuePostalCode,
                country:venueCountry
            },
            venuecontacts:[{
                emailaddress:venueEmailAddress,
                phone:venueContactNumber
            }],
            //metadata: tags,
            venuerooms:venueRooms
        }
        console.info(data);
     
        new RestServer().setUrl(orgurl+'/api/org/updatevenue')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            console.info("rtndata: ");
            console.info(rtndata);
            if(rtndata.messageTypeID===1){
                history('/venues', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
    }
    useEffect(() => {
        const urlData = window.location.pathname.split("/");
        console.info("urlData: ");
        console.info(urlData[2]);

        //GET VENUE INFO
        new RestServer().setUrl(orgurl+'/api/org/byvenueid/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(resp=>{
            const vdata=resp.data;
            setVenue(vdata)
            setVenueId(vdata.venueid)
            setVenuename(vdata.venuename);
            setVenuedescription(vdata.venuedescription);

            //Address
            setVenueAddress(vdata.venueaddress.address1);
            setVenueCity(vdata.venueaddress.city);
            setVenueProvince(vdata.venueaddress.state);
            setVenuePostalCode(vdata.venueaddress.zip);
            setVenueCountry(vdata.venueaddress.country);

            //Contacts
            setVenueContactNumber(vdata.venuecontacts[0].phone);
            setVenueEmailAddress(vdata.venuecontacts[0].emailaddress);

            //BUILD Venue Rooms
            var buildVenueRooms=[];
            vdata.venuerooms.map((vr)=>{
                console.info(vr)
                var vroom={
                    venueroomname:vr.venueRoomName,
                    venueroomdescription:vr.venueRoomDescription,
                    venueroomcapacity:vr.venueRoomCapacity
                }
                buildVenueRooms.push(vroom);
            });
            setVenueRooms(buildVenueRooms);
        });

    },[]);


    return (
        <React.Fragment>
            <Row>
                <Col  lg={10} md={12}>
                    <Row>
                        <Col lg={4} className="p-r-25 p-b-25">
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col>Venue Information</Col>
                            </Row>
                            <Row className=" p-t-25 p-b-50  glass-container-1">
                                <Col>
                                    <Row className="p-t-10">
                                        <Col >
                                            <Form.Group controlId ="venuename" onChange={(s)=>{setVenuename(s.target.value)}}>
                                                <Form.Label>Venue Name</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueName} placeholder="Venue Name" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col>
                                            <Form.Group controlId ="venuedescription" onChange={(s)=>{setVenuedescription(s.target.value)}}>
                                                <Form.Label>Venue Description</Form.Label>
                                                <Form.Control as="textarea" value={venueDescription} rows={4} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecontactnumber" onChange={(s)=>{setVenueContactNumber(s.target.value)}}>
                                                <Form.Label>Venue Contact Number</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueContactNumber} placeholder="Venue Contact Number" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venueemailaddress" onChange={(s)=>{setVenueEmailAddress(s.target.value)}}>
                                                <Form.Label>Venue Email Address</Form.Label>
                                                <Form.Control className="clr-black" type="text"  value={venueEmailAddress} placeholder="Venue Email" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        
                        <Col lg={4} md={12} className="p-r-25">
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col>Venue Address</Col>
                            </Row>
                            <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1 -clr-primary-5">
                                <Col>
                                    <Row className="p-t-10">
                                        <Col >
                                            <Form.Group controlId ="venueaddress" onChange={(s)=>{setVenueAddress(s.target.value)}}>
                                                <Form.Label>Venue Address</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueAddress} placeholder="Venue Address" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecity" onChange={(s)=>{setVenueCity(s.target.value)}}>
                                                <Form.Label>City</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueCity} placeholder="City" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venueprovince" onChange={(s)=>{setVenueProvince(s.target.value)}}>
                                                <Form.Label>Province / State</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueProvince} placeholder="Province" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="vnuepostalcode" onChange={(s)=>{setVenuePostalCode(s.target.value)}}>
                                                <Form.Label>Postal Code / Zip</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venuePostalCode} placeholder="Postal Code" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-25">
                                        <Col >
                                            <Form.Group controlId ="venuecountry" onChange={(s)=>{setVenueCountry(s.target.value)}}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control className="clr-black" type="text" value={venueCountry} placeholder="Country" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={4} md={12}>
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col>Tags</Col>
                            </Row>
                            <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                                    <Col>
                                        <Row>
                                            <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                        </Row>
                                        <Row className="p-t-25 p-l-25 p-r-25">
                                            {
                                                tags.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                                })
                                            }
                                        
                                        </Row>  
                                    </Col>
                                </Row>
                        </Col>

                    </Row>
                    <Row className="p-t-50">
                        <Col  className="p-l-10" lg={11}>
                            <Row className="p-b-25 font-size-125 font-heavy9">
                                <Col lg={2}> Add Venue Rooms</Col>
                                <Col lg={6} className="left" onClick={(s)=>{setShowDepartmentContactsModel(true)}}> <FontAwesomeIcon icon={faPlusSquare} /></Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        venueRooms.map((room)=>{
                                            return<Row>
                                            <Col lg={5} className="glass-container-1 p-25">
                                                <Row className="center"><Col className="font-size-300 clr-primary-6"><FontAwesomeIcon icon={faUsersBetweenLines} /></Col></Row>
                                                <Row className="center"><Col className="font-size-150 clr-primary-6 font-heavy8">{room.venueroomname}</Col></Row>
                                                <Row><Col className="font-75 clr-primary-6 font-light">{room.venueroomdescription}</Col></Row>
                                                <Row><Col className="font-100 clr-primary-6 font-light">Capacity: {room.venueroomcapacity}</Col></Row>
                                                {/*
                                                <Row className="p-t-10 center">
                                                    <Col className="font-100 clr-primary-4 font-light ">
                                                        <FontAwesomeIcon icon={faCalendarPlus} />
                                                    </Col>
                                                    <Col className="font-100 clr-primary-4 font-light">
                                                        <FontAwesomeIcon icon={faMobile} />
                                                    </Col>
                                                    <Col className="font-100 clr-primary-8 font-light">
                                                        <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
                                                    </Col>
                                                    <Col className="font-100 clr-primary-8 font-light">
                                                        <FontAwesomeIcon icon={faImages} />
                                                    </Col>
                                                </Row>
                                        */}
                                            </Col>
                                        </Row>;
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </Col>
                <Col lg={1} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9" onClick={handleUpdateVenue}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Update
                        </button>
                    </Col>
                </Row>
                <Row className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
            </Row>
          

             {/*Add Contact*/}
            <Modal show={showDepartmentContactsModel} onHide={handleCloseDepartmentContactsModel}>
                <Form onSubmit={handleAddVenueRoomForm}>
                <Modal.Header closeButton>
                <Modal.Title>Venue Room Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="p-t-10">
                        <Col >
                            <Form.Group controlId ="venueroomname" onChange={(s)=>{setVenueRoomName(s.target.value)}}>
                                <Form.Label>Venue Room Name</Form.Label>
                                <Form.Control className="clr-black" type="text" placeholder="venue Room name" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="p-t-10">
                        <Col>
                            <Form.Group controlId ="venueroomdescription" onChange={(s)=>{setVenueRoomDescription(s.target.value)}}>
                                <Form.Label>Venue Room Description</Form.Label>
                                <Form.Control as="textarea" rows={4} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="p-t-10">
                        <Col >
                            <Form.Group controlId ="venueroomcapacity" onChange={(s)=>{setVenueRoomCapacity(s.target.value)}}>
                                <Form.Label>Venue Room Capacity</Form.Label>
                                <Form.Control className="clr-black" type="number" placeholder={venueRoomCapacity}  value={venueRoomCapacity} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDepartmentContactsModel}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit">
                    Add Room
                </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    
    );
}

export default EditVenueContent;