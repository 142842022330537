import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App2.css';

import Main from './main';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  useEffect(()=>{
  });

  return (
    <React.Fragment>
      <Main />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
