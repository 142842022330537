import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';


function GenericVerticalBar(props) {

    const[chartData, setChartData]=useState({
        labels: ['Loading'],
        datasets: [
          {
            data: [0],
            backgroundColor: 'rgba(255, 50, 50, 0.9)',
          },
          
        ],
      });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Tooltip,
      );

      


      useEffect(() => {

        //console.info(props.data)
        setChartData(props.data);
      },[]);

    return (
        <React.Fragment>
            <Col>
                <Row>
                    <Col>
                   
                    </Col>
                </Row>
            </Col>
        </React.Fragment>

    );
}
export default GenericVerticalBar;