import React, {useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL COMPONENTS
import RestServer from '../../components/cRestServer';
import { useNavigate } from "react-router-dom";
import {orgurl}  from '../../environment';

import {CommonUtil} from '../../components/CommonUtil';

import defaultvenue from './Venue-Logo-1b.png'

/*TEMP Delete */
import event_loc from './event_loc.png';

import {faEdit, faInfoCircle, faChartPie, faTrash, faPen, faExpandArrowsAlt} from '@fortawesome/free-solid-svg-icons';

import CurrentRevenue from '../datawidget/currentrevenue';
import ProjectedRevenue from '../datawidget/projectedrevenue';
import TicketSold from '../datawidget/ticketsold';
import TicketRedeemed from '../datawidget/ticketredeemed'

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarCheck, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';

function ListVenues(){

    //STORE SYSTEMS
    const { userData} = userDataStore();

    const history = useNavigate();

    const [users, setUsers] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [showMsgModel, setShowMsgModel]=useState(false);
    const [selectedMsgUser, setSelectedMsgUser]=useState({});
    const [venues, setVenues]=useState([]);

    
    const routeClick=(path)=>{
        history(path, {replace:true})
    }

   const delVenue=(eid)=>{

        new RestServer().setUrl(orgurl+'/api/org/venue/'+eid)
        .setMethod('DELETE')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const res=response.data;

            if(res.messageTypeID===1){
                new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(resp=>{
                    setVenues(resp.data);
                });
                
            }
            
        });
   }

    const handleInviteUser=(e)=>{
        setShowModel(true);
       // console.info(store.userdata[0]);
    }

    const handleCloseModel=()=>{
        setShowModel(false);
    }

    const handleMsgUser=(e)=>{
        setShowMsgModel(true);
        setSelectedMsgUser(e);
    }
    const handleCloseMsgModel=()=>{
        setShowMsgModel(false);
    }

    const handleSuspendUser=(e)=>{
    }

    const handleResetPassword=(e)=>{
    }

    useEffect(() => {
        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
           
            setVenues(response.data);
        });
    },[]);

    return(
        <React.Fragment>
        <Row>
            <Col lg={10} md={8}>
                <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                <button className="icon-text-container-1 m-r-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck}  className="p-r-20"/>Active Event</button>
                <button className="icon-text-container-1 m-l-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck} className="p-r-20" />No Event</button>
                
             </Col>
             <Col lg={2} md={4} className="right p-r-50">
             <button className="icon-text-container-0 m-l-100 font-heavy font-size-75" onClick={()=>{routeClick("/addvenue")}}>
                <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Add
            </button>
             </Col>
            </Row>
            <Row className="p-t-25">
            {
                venues?.map((venue)=>{
                    return <Col lg={2} md={5} className="glass-container-1 m-10">
                  
                                <Row className="p-t-10">
                                    <Col>
                                        <img src={defaultvenue}  className="img-container-1 rad-tl-5 rad-tr-5"/>
                                    </Col>
                                </Row>
                               
                                <Row className="p-t-50 font-size-150 font-heavy p-l-10 p-r-10">
                                    <Col>{venue.venuename}</Col>
                                </Row>
                                <Row className="p-t-5 font-size-75 font-heavy p-l-10 p-r-10 clr-primary-4">
                                    <Col>{venue.venueaddress.address1} {venue.venueaddress.city}, {venue.venueaddress.state} {venue.venueaddress.zip}</Col>
                                </Row>
                                <Row className="p-t-25 font-size-75 p-l-10  p-r-10">
                                    <Col>{CommonUtil.Crop(venue.venuedescription)}</Col>
                                </Row>
                                <Row className="p-t-75 p-b-25 p-l-10  p-r-10">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/editvenue/"+venue.venueid)}}>
                                        <FontAwesomeIcon icon={faPen} />
                                        <span className="icon-container-1-title">Edit
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>
                                </Col>
                                    
                                    <Col>
                                        <button className="icon-container-1">
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            <span className="icon-container-1-title">Info
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                        </button>
                                        
                                    </Col>
                                    <Col>
                                        <button className="icon-container-1" onClick={()=>{routeClick("/venuedashboard/"+venue.venueid)}}>
                                            <FontAwesomeIcon icon={faChartPie} />
                                            <span className="icon-container-1-title">Dashboard
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                      
                                        </button>
                                    </Col>
                                    <Col>
                                        <button className="icon-container-1" onClick={()=>{delVenue(venue.venueid)}}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            <span className="icon-container-1-title">Delete
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                        </button>
                                    </Col>
                                </Row>
                        
                            </Col>
                            
                            
                })
            }
               


            </Row>
        
    </React.Fragment>
        /*
        <React.Fragment>
                <Col className="bck-clr-primary-5 rad-5">
                    <Row className="p-b-10">
                        <Col lg={1} className="center v-center"><img src={event_loc}  className="profile-img-3 "/></Col>
                        <Col lg={6} className="p-t-10">
                            <Row className="font-size-150 p-l-5">
                                
                                <Col>{venue.venuename}&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<span className="font-size-50 font-heavy"><a href="#"><FontAwesomeIcon icon={faPen} />&nbsp;&nbsp; &nbsp;Edit Venue</a></span></Col>
                            </Row>
                            <Row className="font-size-75 p-t-10 font-heavy">
                                <Col lg={6}>{venue.venueaddress.address1} {venue.venueaddress.address1}, {venue.venueaddress.city}, {venue.venueaddress.state} {venue.venueaddress.zip}</Col>
                            </Row>
                            <Row className="font-size-75 p-t-10">
                                <Col lg={6}>{venue.venuedescription}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="p-b-10">
                                <CurrentRevenue />
                                <ProjectedRevenue />
                            </Row>
                        </Col>
                    </Row>
                </Col>
        </React.Fragment>
        */
    );
}

export default ListVenues;