import React, {useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faBoxOpen} from '@fortawesome/free-solid-svg-icons';

//INTERNAL COMPONENTS
import TopNav from '../../components/ui/topnav';
import LeftNav from '../../components/ui/leftnav';
import TopRightNav from '../../components/ui/toprightnav';
import BreadCrumbs from '../../components/ui/breadcrumbs';
import RestServer from '../../components/cRestServer';
import {producturl}  from '../../environment';


import EventManagementNav from '../../components/events/eventmanagementnav';
import ListProducts from '../../components/products/listproducts';

//STORES
import userDataStore from '../../stores/zuserdatastore';

function ProductList() {
    const [products, setProducts]=useState(null);

    //STORE SYSTEMS
    const { userData} = userDataStore();

    const basedata={
        title:'Product Dashboard',
        titleicon :<FontAwesomeIcon icon={faUser} />,
        breadcrumb:'Home / Products'
    }

    useEffect(() => {
        new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info(response.data)
            setProducts(response.data);
        });
    },[]);

    return (
        <React.Fragment>
        <Container fluid>
            <Col>
                <Row className="p-t-25">
                    <Col lg={1} md={2} className="p-l-25"><TopNav /></Col>
                    <Col lg={8} md={4} className="font-size-150 font-heavy9 clr-primary-2 p-l-10"> Product Management</Col>
                    <Col lg={3} md={6}><TopRightNav /></Col>
                </Row>
            </Col>
            <Col>
                <Row className="p-t-25">
                    <Col lg={1} md={2}> <LeftNav /></Col>
                    <Col lg={11}  md={10}><ListProducts /></Col>
                </Row>
            </Col>
        </Container>
    </React.Fragment>

    );

}
export default ProductList;